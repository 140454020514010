import React from 'react';
import styled from 'styled-components';
import { FONT_FAMILY } from '../../const/';

const Text = styled.p`
  ${props => isNaN(props.size) ? (`
    font: ${props.theme.font[props.size]};
  `) : (`
    font-family: ${props.theme.fontFamily};
    font-size: ${props.size}px;
    line-height: ${props.height}px;
  `)}
  color: ${({ theme, color }) => theme.text[color] ? theme.text[color] : color};
  text-align: ${({ align }) => align};
  font-weight: ${({ weight }) => weight};
  letter-spacing: ${({ spacing }) => spacing}px;
  text-transform: ${({ transform }) => transform};
  margin: 0;
  width: ${props => props.width}%;
`;

function Typography(props) {
  const {
    children,
    color,
    component,
    size,
    ...other
  } = props;
  return (
    <Text as={component} size={size} color={color} {...other}>
      {children}
    </Text>
  )
}

Typography.defaultProps = {
  size: 's',
  height: 20,
  align: 'left',
  spacing: 0,
  weight: 400,
  transform: 'none',
  color: 'default',
};

export default Typography;
