import React from 'react';

export const FormContext = React.createContext({});

export const FormContextProvider = ({ children, ...props }) => (
  <FormContext.Provider {...props}>{children}</FormContext.Provider>
);

export const FormItemContext = React.createContext({});

export const FormItemContextProvider = ({ children, ...props }) => (
  <FormItemContext.Provider {...props}>{children}</FormItemContext.Provider>
)
