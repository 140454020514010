import React from "react";
import styled from "styled-components";

const StyledP = styled.p`
  font: ${props => props.theme.font[props.size]};
  margin: 0;
  padding: ${props => props.padding};
  font-weight: 400;
  max-width: 640px;
  vertical-align: middle;
  color: ${props => props.theme.text[props.textColor] ? props.theme.text[props.textColor] : props.textColor};
`;

function P(props) {
  const {
    children,
    color,
    size,
    padding,
    ...other
  } = props;
  return (
    <StyledP textColor={color} padding={padding} size={size} {...other}>{children}</StyledP>
  );
}

P.defaultProps = {
  children: "",
  color: 'default',
  padding: 0,
  size: "s",
};

export default P;
