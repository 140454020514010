import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useMenuState } from './menuContext';

const StyledMenuHeader = styled.header`
  flex-shrink: 0;
  flex-grow: 0;
  border-bottom: 1px solid ${({ light }) => light ? 'hsla(0,0%,0%,0.08)' : 'hsla(0,0%,100%,0.08)'};
`;

function MenuHeader(props) {
  const {
    children,
    ...other
  } = props;

  const {
    light
  } = useMenuState();

  return (
    <StyledMenuHeader light={light} {...other}>
      {children}
    </StyledMenuHeader>
  );
}

MenuHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
};

MenuHeader.defaultProps = {
  children: undefined,
};

export default MenuHeader;
