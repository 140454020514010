import React, { useMemo, useState, memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BrickContextProvider } from './BrickContext';

const BricksStyled = styled.div`
  display: grid;
  justify-content: flex-start;
  width: 100%;
  opacity: ${props => props.loaded ? 1 : 0};
`;

function Bricks(props) {
  const {
    children,
    gap,
    columnWidth,
    rowHeight,
    timer = 100,
    ...other
  } = props;
  const [loaded, setLoaded] = useState(false);
  const brickContainerStyle = useMemo(() => {
    return {
      gridGap: `${gap}px`,
      gridTemplateColumns: `repeat(auto-fill, minmax(${columnWidth}px, 1fr))`,
      gridAutoRows: `${rowHeight}px`,
    };
  }, [])

  return (
    <BrickContextProvider value={{ columnWidth, rowHeight, gap, timer, setLoaded }}>
      <BricksStyled loaded={loaded} style={brickContainerStyle} {...other}>{children}</BricksStyled>
    </BrickContextProvider>
  )
}

Bricks.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  gap: PropTypes.number,
  columnWidth: PropTypes.number,
  rowHeight: PropTypes.number,
}

Bricks.defaultProps = {
  children: undefined,
  gap: undefined,
  columnWidth: undefined,
  rowHeight: undefined,
}

export default memo(Bricks);
