import styled from 'styled-components';
import { IGridView } from './types';
import { generateContainerCss, generateItemCss, generateMediaGrid } from './utils';

export const GridView = styled.div<IGridView>`
  box-sizing: border-box;

  ${props => `
    ${props.container ? generateContainerCss(props) : ''}

    ${props.item ? `
      ${generateItemCss(props)}

      ${generateMediaGrid(props)}
    ` : ''}
  `}
`;

