import React, { FC } from 'react';
import useNativeLazyLoading from '@charlietango/use-native-lazy-loading'
import { useInView } from 'react-intersection-observer'
import styled, { css, keyframes } from 'styled-components';

async function loadPolyfills() {
  if (typeof window.IntersectionObserver === 'undefined') {
    // @ts-ignore
    await import('intersection-observer');
  }
}
loadPolyfills();

const loading = keyframes`
  0%{
    background-position: -800px 0;
  }

  100%{
    background-position: 800px 0;
  }
`;

interface PropsWrapper {
  width?: string;
  height?: string;
  borderRadius?: string;
}

interface PrivatePropsImageWrapper extends PropsWrapper {
  hasImage: boolean;
}

interface PropsImage {
  src: string;
  alt?: string;
  lazy?: boolean;
}

export interface Props extends PropsWrapper, PropsImage { }

const ImageWrapper = styled.div<PrivatePropsImageWrapper>`
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: hsla(0,0%,0%,.06);
  ${props => !props.hasImage && css`
    background-image: linear-gradient(270deg, rgba(255,255,255,0.00) 0%, rgba(255,255,255,0.32) 50%, rgba(255,255,255,0.00) 100%);
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border: none;
    border-radius: ${props.borderRadius}px;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ${loading};
  `}
`;

const ImageStyled = styled.img<PropsImage>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Image: FC<Props> = ({
  src,
  alt,
  width = '100%',
  height = '100%',
  lazy = false,
  borderRadius,
}) => {
  const supportsLazyLoading = useNativeLazyLoading();
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });
  console.log(src, lazy, inView, supportsLazyLoading)
  return (
    <ImageWrapper
      width={width}
      height={height}
      borderRadius={borderRadius}
      hasImage={Boolean(src)}
      ref={(lazy && !supportsLazyLoading) ? ref : undefined}
    >
      {src && (!lazy || (inView || supportsLazyLoading)) && (
        <ImageStyled
          src={src}
          alt={alt}
          loading={lazy ? 'lazy' : 'eager'}
        />
      )}
    </ImageWrapper >
  );
}

export default Image;
