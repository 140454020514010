import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Logo } from "../../elements/";
import { ModalIcon } from "./";

const StyledModalHeader = styled.div`
  display: flex;
  flex-flow: row: nowrap;
  justify-content: center;
  padding: 12px 0 20px;
`;

function ModalHeader(props) {
  const {
    children,
    logo,
    icon,
    iconColor,
    ...other
  } = props;
  return (
    <StyledModalHeader {...other}>
      {logo && <Logo logo={logo} />}
      {icon && <ModalIcon icon={icon} color={iconColor} />}
      {children}
    </StyledModalHeader>
  )
}

ModalHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  logo: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
}

ModalHeader.defaultProps = {
  children: undefined,
  logo: "3divi",
  icon: "",
  iconColor: "",
};

export default ModalHeader;
