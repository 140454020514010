import react, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

let portalDiv = document.getElementById('root-portal');
let bodyDiv = document.querySelectorAll('body > div');
const portalRoot = portalDiv || bodyDiv[bodyDiv.length - 1];

const Portal = ({ isOpen, children, getRef }) => {
  const elRef = useRef(null)
  if (!elRef.current) {
    elRef.current = document.createElement('div')
  }
  const el = elRef.current

  useEffect(() => {
    document.body.appendChild(el);
    if (getRef)
      getRef.current = el;
    return () => {
      document.body.removeChild(el)
    }
  }, [])

  return isOpen ? ReactDOM.createPortal(
    children,
    el
  ) : '';
}

export default Portal;
