import React, { useMemo } from 'react';
import styled from 'styled-components';
import { PagesProps } from '../types';
import PagePointer from './PagePointer';

const PG_MAX_OFFSET_COUNT = 2;

const PointersWrap = styled.div`
  display: inline-flex;
`;

function PagePointers({ 
  pagesCount, currentPage, changePage }: PagesProps) {

  // АРГУМЕНТЫ И ЛОГИКУ СМЕНЫ СТРАНИЦ СЛЕДУЕТ В БУДУЩЕМ ОБЪЕДИНИТЬ 
  // В ПРОГРАММНЫЙ КЛАСС, Т.К. ИСПОЛЬЗУЕМЫЕ ОБЪЕКТЫ ВЗАИМОСВЯЗАНЫ!
  // ТЕКУЩЕЕ ИСПОЛЬЗОВАНИЕ МОЖЕТ ПРИВЕСТИ К НЕСОГЛАСОВАННЫМ ДАННЫМ
  const pages = useMemo(
    () => {
      if (pagesCount === 1) return [];

      const cpgDownOffset = currentPage - PG_MAX_OFFSET_COUNT;
      const cpgUpOffset = currentPage + PG_MAX_OFFSET_COUNT;
      
      const pgNumToDisplay1st = cpgDownOffset > 0 ? cpgDownOffset : 1;
      const pgNumToDisplayLast = cpgUpOffset > pagesCount ? pagesCount :
        cpgUpOffset;

      const pgNumbers = [];
      for (let i = pgNumToDisplay1st; i <= pgNumToDisplayLast; i++)
        pgNumbers.push(i);
      return pgNumbers;
    }, [
      currentPage, 
      pagesCount
    ]
  );


  return (
    <PointersWrap>
      {pages.map(pgNum => (
        <PagePointer 
          onClick={changePage}
          isActive={pgNum === currentPage}
          key={pgNum}
          count={pgNum}
        />
      ))}
    </PointersWrap>
  )
}

export default PagePointers;
