import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormItemContext } from '../../blocks/Form/FormContextProvider';
import { Input as BaseInput} from '../../elements/';

function Input(props) {
  const {
    label,
    afterLabelTooltip,
    name,
    type,
    children,
    afterErrorTooltip,
    placeholder,
    readOnly,
    inputRef,
    maxlength,
    size,
    variant,
    tabIndex,
    lines,
    borderRadius,
    removeSpaces,
    autoFocus,
    rules,
    dependences,
    error,
    defaultValue,
    callback,
    ...other
  } = props;
  (maxlength === '30' || maxlength === 30) && console.warn('Длина поля дефолтная!');

  const { value, onChange, onBlur, errorText, isValid, setDataField } = useContext(FormItemContext);

  const errorMessage = error && error.message;

  useEffect(() => {
    setDataField && setDataField({ name, rules, dependences, error });
  }, [errorMessage]);

  useEffect(() => {
    if (defaultValue)
      onChange(defaultValue);
  }, [defaultValue]);

  const handlerChange = value => {
    onChange(value);
    callback && callback(value);
  }

  const handlerBlur = e => {
    const trimmedValue = e.target.value.trim();
    removeSpaces && value && trimmedValue !== value && onChange(trimmedValue, e);
    onBlur && onBlur(removeSpaces ? trimmedValue : e.target.value, e);
  }

  return (
    <BaseInput
      label={label}
      afterLabelTooltip={afterLabelTooltip}
      name={name}
      type={type}
      afterErrorTooltip={afterErrorTooltip}
      placeholder={placeholder}
      readOnly={readOnly}
      inputRef={inputRef}
      maxlength={maxlength}
      size={size}
      variant={variant}
      tabIndex={tabIndex}
      lines={lines}
      borderRadius={borderRadius}
      removeSpaces={removeSpaces}
      autoFocus={autoFocus}
      errorText={errorText}
      value={value}

      isValid={isValid}
      onChange={handlerChange}
      handlerBlur={handlerBlur}

      {...other}
    />
  );
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  //rules: ,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  //inputRef: ,
  maxlength: PropTypes.string,
  //dependences: ,
  size: PropTypes.string,
  variant: PropTypes.string,
  tabIndex: PropTypes.string,
  lines: PropTypes.string,
  borderRadius: PropTypes.string,
  error: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
};

Input.defaultProps = {
  inputRef: undefined,
  maxlength: '30',
  readonly: false,
  type: 'text',
  removeSpaces: true,
  size: 's',
  variant: 'line',
  lines: '4',
  borderRadius: '4px',
  autoFocus: false,
};

export default Input;
