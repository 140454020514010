import React from 'react';
import styled from 'styled-components';
import { Box } from '../../../blocks';
import Button from '../../Button';
import PagePointers from './PagePointers';
import { ControlsLabel, PagesProps } from './types';


const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
`;


function PaginationControls({
  currentPage, pagesCount, changePage, label }: PagesProps & {
    label: Omit<ControlsLabel, 'showMore'>;
  }) {

  const onPrevPageClick = () => changePage(currentPage - 1);
  const onNextPageClick = () => changePage(currentPage + 1);

  return (
    <ControlsWrapper>
      {currentPage > 1 && (<Box pr={1.5}>
        <Button
          variant="clear"
          hoverColor="primary"
          onClick={onPrevPageClick}
          text={`← ${label.back}`}
        />
      </Box>)}

      <PagePointers
        changePage={changePage}
        pagesCount={pagesCount}
        currentPage={currentPage}
      />

      {currentPage < pagesCount && (<Box pl={1.5}>
        <Button
          variant="clear"
          hoverColor="primary"
          onClick={onNextPageClick}
          text={`${label.next} →`}
        />
      </Box>)}
    </ControlsWrapper>
  )
}

export default PaginationControls;
