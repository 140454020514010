import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { SectionHeader, SectionBody, SectionBox } from '../Section/';
import { TEXT_COLORS } from '../../const/';

function Section(props) {
  const {
    title,
    subtitle,
    buttons,
    children,
    headerLink,
    borderHeader,
    icon,
    hoverColor,
    ...other
  } = props;
  return (
    <SectionBox {...other}>
      {(title || subtitle || buttons || icon) && (
        <SectionHeader headerLink={headerLink} title={title} subtitle={subtitle} buttons={buttons} icon={icon} borderHeader={borderHeader} hoverColor={hoverColor} />
      )}
      {children && (
        <SectionBody>{children}</SectionBody>
      )}
    </SectionBox>
  );
}

Section.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  subtitle: PropTypes.string,
  headerLink: PropTypes.string,
  buttons: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  icon: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  borderHeader: PropTypes.bool,
  hoverColor: PropTypes.string,
}

Section.defaultProps = {
  title: "",
  subtitle: "",
  headerLink: "",
  buttons: undefined,
  icon: "",
  children: undefined,
  borderHeader: false,
  hoverColor: TEXT_COLORS.primary,
}

export default Section;
