import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

const StyledSectionBody = styled.div`
  padding: 0 16px;
`;

function SectionBody(props) {
  const {
    children,
    ...other
  } = props;
  return (
    <StyledSectionBody>{children}</StyledSectionBody>
  );
}

SectionBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
}

SectionBody.defaultProps = {
  children: undefined,
}

export default SectionBody;
