import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Portal from "../../blocks/Portal/";
import Notification from "./Notification";

const NotificationsStyle = styled.div`
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  bottom: 16px;
  overflow: hidden;
  z-index: 99999;

  .notification_anim-enter {
    opacity: 0;
    transform: translateX(${(props) => (props.side === "left" ? "-100" : "100")}%);
  }
  .notification_anim-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 200ms ease, opacity 200ms ease;
  }
  .notification_anim-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .notification_anim-exit-active {
    opacity: 0;
    transform: translateX(${(props) => (props.side === "left" ? "-100" : "100")}%);
    transition: transform 200ms ease, opacity 200ms ease;
  }

  ${(props) => (props.side === "left" ? "left: 24px;" : "right: 24px")}
`;

function Notifications({ notifications, side = "left", onRequestHide }) {
  const handleRequestHide = (notification) => () => {
    if (onRequestHide) {
      onRequestHide(notification);
    }
  };

  return (
    <Portal isOpen={true}>
      <NotificationsStyle side={side}>
        <TransitionGroup>
          {notifications.map((notification) => (
            <CSSTransition in={false} key={notification.id} timeout={200} classNames="notification_anim">
              <Notification
                isOnly={notifications && notifications.length === 1}
                onRequestHide={handleRequestHide(notification)}
                {...notification}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </NotificationsStyle>
    </Portal>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.array,
  requestHide: PropTypes.func
}

Notifications.defaultProps = {
  notifications: [],
  requestHide: undefined,
};

export default Notifications;
