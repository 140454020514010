import styled from "styled-components";
import { DIVIDER_ORIENTATIONS, IDivider } from "../../elements/Divider/types";

import { Button, Divider } from "../../elements";
import { TABS_LINE_POSITIONS } from "./types";

// Tabs
export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const ScrollWrapper = styled.div<{ orientation: keyof typeof DIVIDER_ORIENTATIONS; }>`
  flex: 0 1 auto;
  display: inline-block;
  white-space: nowrap;
  ${props => `
    overflow-${props.orientation.toLowerCase()}: scroll;
  `}
  scrollbar-width: none;
`;

export const Line = styled(Divider) <IDivider & { dimension?: number; offset?: number; orientation: keyof typeof DIVIDER_ORIENTATIONS; position: keyof typeof TABS_LINE_POSITIONS }>`
  position: absolute;
  background-color: ${props => props.theme.background.brand};

  ${props => props.orientation === DIVIDER_ORIENTATIONS.X ? `
    width: ${props.dimension}px;
    transform: translate(${props.offset}px, 0%);
    bottom: 0;
    transition: width 0.2s, transform 0.2s;
  ` : `
    height: ${props.dimension}px;
    transform: translate(0, ${props.offset}px);
    top: 0;
    transition: transform 0.2s, height 0.2s;
  `}

  ${props => props.orientation === DIVIDER_ORIENTATIONS.Y && `
    ${props.position}: 0;
  `}
`;


// Tab
export const TabView = styled.div`
  width: 100%;
  white-space: nowrap;
`;

export const IconWrap = styled.div`
  display: block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export const TabButton = styled(Button)`
  opacity: .56;

  &:hover, &:focus {
    opacity: 1;
  }

  ${props => props.isActive && `
    opacity: 1;
  `}
`;