import React, { createContext, useContext, useState, useEffect } from 'react'

const MenuContext = createContext();

function MenuProvider(props) {
  const {
    children,
    small,
    light
  } = props;
  const [state, setState] = useState({ light: light, small: small });

  useEffect(() => {
    setState({ light: light, small: small });
  }, [small, light]);

  return (
    <MenuContext.Provider value={state}>
      {children}
    </MenuContext.Provider>
  )
}

function useMenuState() {
  const state = useContext(MenuContext);
  if (state === undefined) {
    throw new Error('useMenuState must be used within a MenuProvider')
  }
  return state
}

export { MenuProvider, useMenuState };
