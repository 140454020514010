import React, { useState, useContext, useEffect, useMemo } from "react";
import styled from 'styled-components';
import validation from './validation';

import { FormContext, FormItemContextProvider } from './FormContextProvider';

const FormItemView = styled.div``;

function FormItem({ children }) {
  const { type, values, errors, callbackSetValues, callbackSetErrors, lastChangedField, submited } = useContext(FormContext);
  const [value, setValue] = useState('');
  const [dataField, setDataField] = useState({});
  const { rules, name, dependences, error } = dataField;
  const dataDependeces = useMemo(() => creatingObjectDependentFields(values, dependences), [dependences, values]);

  // Initialize the values and erros
  useEffect(() => {
    if (name) {
      callbackSetValues({ value, name });
      if (rules) {
        callbackSetErrors({
          name,
          showError: false,
          ...validation(value, rules)
        });
      }
    }
  }, [dataField.name]);

  useEffect(() => {
    if (error){
      callbackSetErrors({
        name,
        showError: !!error.message,
        isValid: !error.message,
        message: error.message,
      })
    }
  }, [error]);

  // Validate when dependent fields change
  useEffect(() => {
    if (dataDependeces) {
      Object.keys(dataDependeces).forEach(el => {
        if (dataDependeces[el] && value && type === 'change' && name !== lastChangedField)
          handlerValidation(value, true);
      });
    }
  }, [dataDependeces]);

  // submited changes every time when form submiting
  useEffect(() => {
    if (submited)
      handlerValidation(value, true);
  }, [submited]);

  const handlerChangeField = (value) => {
    setValue(value);
    name && callbackSetValues({ value, name });
    error && typeof error.callback === 'function' && error.callback();
    if (type === 'change' && rules)
      callbackSetErrors({
        name,
        showError: true,
        ...validation(value, rules, dataDependeces)
      });
    if (type === 'blur')
      callbackSetErrors({
        name,
        showError: false,
      })
  }

  const handlerValidation = (value, show) => {
    if (rules)
      callbackSetErrors({
        name,
        showError: show ? show : (type === "blur"),
        ...validation(value, rules, dataDependeces)
      });
  }

  return (
    <FormItemContextProvider
      value={{
        value,
        setDataField,
        onChange: handlerChangeField,
        onBlur: type === "blur" && handlerValidation,
        errorText: errors[name] ? (errors[name].showError ? errors[name].message : "") : "",
        isValid: errors[name] && errors[name].isValid,
      }}
    >
      <FormItemView>
        {children}
      </FormItemView>
    </FormItemContextProvider>
  );
}

function creatingObjectDependentFields(dataValues, dependences) {
  if (!dependences) return null;
  let obj = {};
  dependences.forEach(el => obj[el] = dataValues[el]);
  return obj;
}

export default FormItem;
