import React from 'react';
import styled from 'styled-components';
import { Button } from '../../elements/';
import { FONTS, COLORS, TEXT_SIZE, LINE_SIZE } from '../../const/';

const StyledMessage = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  border-radius: 4px;
  padding: 12px 16px;
  background: ${props => props.color ? props.color  : 'hsla(0,0%,0%,.88)'};
  max-width: 320px;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Text = styled.span`
  display: inline-block;
  color: ${COLORS.white};
  font: ${FONTS.s};
  padding: 4px 16px;
`;

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.earlyClose = this.earlyClose.bind(this);
  }

  componentDidMount() {
    var showTime = this.props.time;
    var onRemove = this.props.onRemove;

    if(showTime) {
      this.removePromise = setTimeout(() => onRemove(), showTime * 1000);
    }
  }

  earlyClose(event) {
    if (this.removePromise !== undefined) {
      clearTimeout(this.removePromise);
    }
    this.props.onRemove();
  }

  render() {
    return(
      <StyledMessage {...this.props}>
        {this.props.message && <Text>{this.props.message}</Text>}
        {this.props.children}
        {this.props.type !== "infinity" && (
          <Button iconLeft="close" variant="clear" color="light" round onClick={this.earlyClose} />
        )}
      </StyledMessage>
    );
  }
}

export default Message;
