import React, { createContext } from "react";
import { BUTTON_SIZE } from "../../const";
import { value } from "./types";

const TabsContext = createContext<Partial<{
  activeValue: value,
  tabSize: keyof typeof BUTTON_SIZE;
  onChange: (e: React.MouseEvent<HTMLElement, MouseEvent>, newValue: value) => void,
  setActiveTab: (tab: HTMLElement | undefined) => void
}>>({});
const TabsContextProvider = TabsContext.Provider;

export { TabsContext, TabsContextProvider };
