import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Utils } from '../../utils';
import { SliderArgs, SliderBarColor, SliderBgColor } from './types';
import Trackbar from './Trackbar';


const HiddenRange = styled.input`
  display: block;
  z-index: 100;
  opacity: 0;
  cursor: pointer;
`;


function Slider({
  value = 0, bgColor = SliderBgColor.main,
  min, max, onChangeBegin, onChange, onChangeEnd,
  ...rest
}: SliderArgs) {

  const calcPercentage = Utils.calcPercentageInRange;
  const [percentage, setPercentage] = useState(
    calcPercentage(value, min, max)
  );

  const isSeeking = useRef(false);
  const [hiddenValue, setHiddenValue] = useState(percentage);

  const calcAndSetPercentage = (value: number) => {
    const result = calcPercentage(value, min, max);
    setHiddenValue(value);
    setPercentage(result);
  }


  useEffect(
    () => {
      if (isSeeking.current || !value) return;
      calcAndSetPercentage(value);
    },
    [value]
  )


  const trackChangeBegin = (e: React.PointerEvent) => {
    if (onChangeBegin) onChangeBegin(e);
    isSeeking.current = true;
  }


  const trackChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    calcAndSetPercentage(+e.target.value);
  }


  const trackChangeEnd = (e: React.PointerEvent) => {
    if (onChangeEnd) onChangeEnd(e);
    isSeeking.current = false;
  }


  return (
    <Trackbar
      value={percentage}
      bgColor={bgColor}
      barColor={SliderBarColor.primary}
    >
      <HiddenRange
        type='range'
        value={hiddenValue}
        onPointerDown={trackChangeBegin}
        onChange={trackChange}
        onPointerUp={trackChangeEnd}
        min={min}
        max={max}
        {...rest}
      ></HiddenRange>
    </Trackbar>
  )
}


export default Slider;

