const calculateStyle = (elem, tooltip, side, afterSize, space, afterVMarginFromTooltipBorder, afterHMarginFromTooltipBorder, tooltipViewportMargin, afterVMarginFromChildrenBorder, afterHPaddingFromBorder) => {
  const elemBounding = elem.getBoundingClientRect();
  const tooltipBounding = tooltip.getBoundingClientRect();
  const [sideDirection, alignment] = side.split('-');
  let tooltipStyle = {};
  let afterLeft;
  let afterTop;

  if (sideDirection === "top" || sideDirection === "bottom") {
    let [resultStyle, afterLeftCalc] = getTopBottomPosition(side, elemBounding, tooltipBounding, afterSize, space, afterVMarginFromTooltipBorder, tooltipViewportMargin, afterVMarginFromChildrenBorder);
    afterLeft = afterLeftCalc;
    tooltipStyle = resultStyle;
  }

  if (sideDirection === "left" || sideDirection === "right") {
    let [resultStyle, afterTopCalc] = getLeftRightPosition(side, elemBounding, tooltipBounding, afterSize, space, afterHMarginFromTooltipBorder, tooltipViewportMargin, afterHPaddingFromBorder);
    afterTop = afterTopCalc;
    tooltipStyle = resultStyle;
  }

  return [tooltipStyle, afterLeft, afterTop];
}

function getLeftRightPosition(side, elemBounding, tooltipBounding, afterSize, space, afterHMarginFromTooltipBorder, tooltipViewportMargin, afterHPaddingFromBorder) {
  const [sideDirection, alignment] = side.split('-');
  const style = {};
  const halfAfterSize = afterSize / 2.;

  if (sideDirection === "left")
    style.left = elemBounding.left - tooltipBounding.width - halfAfterSize - space;

  if (sideDirection === "right")
    style.left = elemBounding.left + elemBounding.width + halfAfterSize + space;

  let afterTop;
  if (alignment === "start") {
    afterTop = setTooltipAfterTop(afterHMarginFromTooltipBorder, afterSize, tooltipBounding, afterHMarginFromTooltipBorder);
    style.top = elemBounding.top;

    const compensate = viewportCompensateTop(afterTop, style.top, halfAfterSize, tooltipBounding, elemBounding, afterHMarginFromTooltipBorder, tooltipViewportMargin, afterHPaddingFromBorder);
    afterTop = compensate.after.top;
    style.top = compensate.tooltip.top;
  } else if (alignment === "end") {
    afterTop = setTooltipAfterTop(tooltipBounding.height - afterSize - afterHMarginFromTooltipBorder, afterSize, tooltipBounding, afterHMarginFromTooltipBorder);
    style.top = elemBounding.top + elemBounding.height - tooltipBounding.height;

    const compensate = viewportCompensateTop(afterTop, style.top, halfAfterSize, tooltipBounding, elemBounding, afterHMarginFromTooltipBorder, tooltipViewportMargin, afterHPaddingFromBorder);
    afterTop = compensate.after.top;
    style.top = compensate.tooltip.top;
  } else { // aligment === "center" by default
    afterTop = setTooltipAfterTop(tooltipBounding.height * 0.5 - halfAfterSize, afterSize, tooltipBounding, afterHMarginFromTooltipBorder);
    style.top = elemBounding.top + elemBounding.height / 2 - tooltipBounding.height / 2;

    const compensate = viewportCompensateTop(afterTop, style.top, halfAfterSize, tooltipBounding, elemBounding, afterHMarginFromTooltipBorder, tooltipViewportMargin, afterHPaddingFromBorder);
    afterTop = compensate.after.top;
    style.top = compensate.tooltip.top;
  }

  style.top += pageYOffset;
  style.left += pageXOffset;

  return [style, afterTop];
}

function getTopBottomPosition(side, elemBounding, tooltipBounding, afterSize, space, afterVMarginFromTooltipBorder, tooltipViewportMargin, afterVMarginFromChildrenBorder) {
  const [sideDirection, alignment] = side.split('-');
  const style = {};
  const halfAfterSize = afterSize / 2.;

  if (sideDirection === "top")
    style.top = elemBounding.top - tooltipBounding.height - halfAfterSize - space;

  if (sideDirection === "bottom")
    style.top = elemBounding.top + elemBounding.height + halfAfterSize + space;

  let afterLeft;
  if (alignment === "start") {
    afterLeft = setTooltipAfterLeft(afterVMarginFromTooltipBorder, afterSize, tooltipBounding, afterVMarginFromTooltipBorder);
    style.left = elemBounding.left;

    const compensate = viewportCompensateLeft(afterLeft, style.left, halfAfterSize, tooltipBounding, elemBounding, afterVMarginFromTooltipBorder, tooltipViewportMargin, afterVMarginFromChildrenBorder);
    afterLeft = compensate.after.left;
    style.left = compensate.tooltip.left;
  } else if (alignment === "end") {
    afterLeft = setTooltipAfterLeft(tooltipBounding.width - afterSize - afterVMarginFromTooltipBorder, afterSize, tooltipBounding, afterVMarginFromTooltipBorder);
    style.left = elemBounding.left + elemBounding.width - tooltipBounding.width;

    const compensate = viewportCompensateLeft(afterLeft, style.left, halfAfterSize, tooltipBounding, elemBounding, afterVMarginFromTooltipBorder, tooltipViewportMargin, afterVMarginFromChildrenBorder);
    afterLeft = compensate.after.left;
    style.left = compensate.tooltip.left;
  } else { // aligment === "center" by default
    afterLeft = setTooltipAfterLeft(tooltipBounding.width * 0.5 - halfAfterSize, afterSize, tooltipBounding, afterVMarginFromTooltipBorder);
    style.left = elemBounding.left + elemBounding.width / 2 - tooltipBounding.width / 2;

    const compensate = viewportCompensateLeft(afterLeft, style.left, halfAfterSize, tooltipBounding, elemBounding, afterVMarginFromTooltipBorder, tooltipViewportMargin, afterVMarginFromChildrenBorder);
    afterLeft = compensate.after.left;
    style.left = compensate.tooltip.left;
  }

  style.top += pageYOffset;
  style.left += pageXOffset;

  return [style, afterLeft];
}

// invert sideDirection if it dont fit to viewport
export function getSide(elem, tooltip, baseSide, afterSize, space, tooltipViewportMargin) {
  const elemBounding = elem.getBoundingClientRect();
  const tooltipBounding = tooltip.getBoundingClientRect();
  const [sideDirection, alignment] = baseSide.split('-');
  let result;
  if (sideDirection === 'top' && elemBounding.top - tooltipBounding.height - afterSize / 2. - space - tooltipViewportMargin < 0){
    result = 'bottom';
  } else if (sideDirection === 'bottom' && elemBounding.top + elemBounding.height + tooltipBounding.height + afterSize / 2. + space + tooltipViewportMargin > document.documentElement.clientHeight){
    result = 'top';
  } else if (sideDirection === 'left' && elemBounding.left - tooltipBounding.width - afterSize / 2. - space - tooltipViewportMargin < 0){
    result = 'right';
  } else if (sideDirection === 'right' && elemBounding.left + elemBounding.width + tooltipBounding.width + afterSize / 2. + space + tooltipViewportMargin > document.documentElement.clientWidth){
    result = 'left';
  } else {
    result = sideDirection;
  }
  return `${result}-${alignment}`;
}

const setTooltipAfterLeft = (left, afterSize, tooltipBounding, afterVMarginFromTooltipBorder) => {
  if (left < afterVMarginFromTooltipBorder)
    return afterVMarginFromTooltipBorder;
  if (left > tooltipBounding.width - afterVMarginFromTooltipBorder - afterSize)
    return tooltipBounding.width - afterVMarginFromTooltipBorder - afterSize;
  return left;
}

const setTooltipAfterTop = (top, afterSize, tooltipBounding, afterHMarginFromTooltipBorder) => {
  if (top < afterHMarginFromTooltipBorder)
    return afterHMarginFromTooltipBorder;
  if (top > tooltipBounding.height - afterHMarginFromTooltipBorder - afterSize)
    return tooltipBounding.height - afterHMarginFromTooltipBorder - afterSize;
  return top;
}

const viewportCompensateLeft = (afterLeft, tooltipLeft, halfAfterSize, tooltipBounding, elemBounding, afterVMarginFromTooltipBorder, tooltipViewportMargin, afterVMarginFromChildrenBorder) => {
  let result = {after: {left: afterLeft}, tooltip: {left: tooltipLeft}};
  let needMoveRight, needMoveLeft;

  if (tooltipLeft < tooltipViewportMargin){
    needMoveRight = tooltipViewportMargin - tooltipLeft;
    result.tooltip.left = tooltipLeft + needMoveRight;
    result.after.left = setTooltipAfterLeft(afterLeft - needMoveRight, halfAfterSize * 2, tooltipBounding, afterVMarginFromTooltipBorder);
    if (getAbsoluteAfterLeft(result.after, result.tooltip) + halfAfterSize > elemBounding.left + elemBounding.width - afterVMarginFromChildrenBorder){
      needMoveLeft = getAbsoluteAfterLeft(result.after, result.tooltip) + halfAfterSize - (elemBounding.left + elemBounding.width - afterVMarginFromChildrenBorder);
      result.tooltip.left = result.tooltip.left - needMoveLeft;
    }
  }

  if (tooltipLeft + tooltipBounding.width > document.documentElement.clientWidth - tooltipViewportMargin){
    needMoveLeft = tooltipLeft + tooltipBounding.width - (document.documentElement.clientWidth - tooltipViewportMargin);
    result.tooltip.left = tooltipLeft - needMoveLeft;
    result.after.left = setTooltipAfterLeft(afterLeft + needMoveLeft, halfAfterSize * 2, tooltipBounding, afterVMarginFromTooltipBorder);
    if (getAbsoluteAfterLeft(result.after, result.tooltip) + halfAfterSize < elemBounding.left + afterVMarginFromChildrenBorder){
      needMoveRight = elemBounding.left + afterVMarginFromChildrenBorder - (getAbsoluteAfterLeft(result.after, result.tooltip) + halfAfterSize);
      result.tooltip.left = result.tooltip.left + needMoveRight;
    }
  }

  return result;
}

const viewportCompensateTop = (afterTop, tooltipTop, halfAfterSize, tooltipBounding, elemBounding, afterHMarginFromTooltipBorder, tooltipViewportMargin, afterHPaddingFromBorder) => {
  let result = {tooltip: {top: tooltipTop}, after: {top: afterTop}};
  let needMoveTop, needMoveBottom;

  if (tooltipTop < tooltipViewportMargin){
    needMoveBottom = tooltipViewportMargin - tooltipTop;
    result.tooltip.top = tooltipTop + needMoveBottom;
    result.after.top = setTooltipAfterTop(afterTop - needMoveBottom, halfAfterSize * 2, tooltipBounding, afterHMarginFromTooltipBorder);
    if (getAbsoluteAfterTop(result.after, result.tooltip) + halfAfterSize > elemBounding.top + elemBounding.height - afterHPaddingFromBorder){
      needMoveTop = getAbsoluteAfterTop(result.after, result.tooltip) + halfAfterSize - (elemBounding.top + elemBounding.height - afterHPaddingFromBorder);
      result.tooltip.top = result.tooltip.top - needMoveTop;
    }
  }

  if (tooltipTop + tooltipBounding.height > document.documentElement.clientHeight - tooltipViewportMargin){
    needMoveTop = tooltipTop + tooltipBounding.height - (document.documentElement.clientHeight - tooltipViewportMargin);
    result.tooltip.top = tooltipTop - needMoveTop;
    result.after.top = setTooltipAfterTop(afterTop + needMoveTop, halfAfterSize * 2, tooltipBounding, afterHMarginFromTooltipBorder);
    if (getAbsoluteAfterTop(result.after, result.tooltip) + halfAfterSize < elemBounding.top + afterHPaddingFromBorder){
      needMoveBottom = elemBounding.top + afterHPaddingFromBorder - (getAbsoluteAfterTop(result.after, result.tooltip) + halfAfterSize);
      result.tooltip.top = result.tooltip.top + needMoveBottom;
    }
  }

  return result;
}

const getAbsoluteAfterLeft = (after, tooltip) => {
  return after.left + tooltip.left;
}

const getAbsoluteAfterTop = (after, tooltip) => {
  return after.top + tooltip.top;
}

export default calculateStyle;