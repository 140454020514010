import React from "react";
import styled from "styled-components";

const hSize = {
  h1: "xxl",
  h2: "xl",
  h3: "l",
  h4: "m",
  h5: "s",
}

const StyledH = styled.div`
  font: ${props => props.as ? props.theme.font[hSize[props.as]] : props.theme.font.xxl};
  margin: 0;
  padding: ${props => props.padding};
  font-weight: 600;
  color: ${props => props.theme.text[props.textColor] ? props.theme.text[props.textColor] : props.textColor};
  text-overflow: ellipsis;
  overflow: hidden;
`;

function H(props) {
  const {
    as,
    children,
    color,
    padding,
    ...other
  } = props;
  return (
    <StyledH as={as} textColor={color} padding={padding} {...other}>{children}</StyledH>
  );
}

H.defaultProps = {
  as: "h1",
  children: "",
  color: 'default',
  padding: "8px 0",
};

export default H;
