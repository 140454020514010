import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  margin: 0 -8px;
`;

function Row(props) {
  const {
    children,
    ...other
  } = props;
  return (
    <StyledRow {...other}>{children}</StyledRow>
  );
}

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
};

Row.defaultProps = {
  children: undefined,
};

export default Row;
