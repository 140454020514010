import { isLight, colorToHsla } from '../../assistants/';

const getHoverActiveColor = color => {
  const [h,s,l,a] = colorToHsla(color);
  return `hsla(${h},${s}%,${+ l + 4 >= 100 ? + l - 4 : + l + 4}%,${a})`;
}

const getTxtColor = color => {
  const [h,s,l,a] = colorToHsla(color);
  return h === '0' && s === '0' && l > 12 ? `hsla(${h},${s}%,${12}%,${a})` : color;
}

const getFocusBorderColor = color => {
  const [h,s,l,a] = colorToHsla(color);
  return `hsla(${h},${s}%,${l}%,${.32})`
}

const getHoverActiveColorHelper = color => {
  const [h,s,l,a] = colorToHsla(color);
  return h === '0' && s === '0' && l > 12 ? `hsla(${h},${s}%,${12}%,${.06})` : `hsla(${h},${s}%,${l}%,${.06})`
}

const getColors = (color, hoverColor, iconColor, variant, theme) => {
  const btnColor = color === 'default' || color === 'primary' || color === 'secondary' || color === 'contrast' || color === 'warning' || color === 'error' || color === 'success' ? theme.button[color] : color;
  const btnHoverColor = theme.button[hoverColor];

  const [h,s,l,a] = colorToHsla(btnColor);

  const defaultBtn = variant === 'contained' ? btnColor : 'transparent';
  const hoverBtn = variant === 'contained' ? btnHoverColor ? btnHoverColor :
    getHoverActiveColor(btnColor) :
      getHoverActiveColorHelper(btnHoverColor ? btnHoverColor : btnColor);

  const activeBtn = variant === 'contained' ? getHoverActiveColor(hoverBtn) : hoverBtn;
  const disabledBtn = variant === 'contained' ? theme.button.default : 'transparent';

  const defaultBorder = variant === 'outlined' ? `hsla(${h},${s}%,${l}%,${.72})` : 'transparent';
  const hoverBorder = variant === 'outlined' ? btnHoverColor ? btnHoverColor :
    getHoverActiveColor(btnColor) :
      'transparent';

  const activeBorder = variant === 'outlined' ? getHoverActiveColor(hoverBorder) : 'transparent';
  const focusBorder = variant === 'clear' ? 'transparent' : getFocusBorderColor(btnHoverColor ? btnHoverColor : btnColor);
  const disabledBorder = variant === 'outlined' ? theme.button.default : 'transparent';

  const defaultTxt = variant === 'contained' ? (isLight(btnColor) ? theme.text.dark : theme.text.light) :
    (theme.text[color] ? theme.text[color] : getTxtColor(btnColor));

  const hoverTxt = variant === 'contained' ? (btnHoverColor ? (isLight(btnHoverColor) ? theme.text.dark : theme.text.light) :
    (isLight(hoverBtn) ? theme.text.dark : theme.text.light)) :
      btnHoverColor ? btnHoverColor : defaultTxt;

  const activeTxt = variant === 'contained' ? (isLight(activeBtn) ? theme.text.dark : theme.text.light) :
    hoverTxt;

  const disabledTxt = variant === 'contained' ? (isLight(theme.button.default) ? theme.text.disabled : theme.text.disabled) :
    theme.text.disabled;

  const defaultIcon = iconColor ? iconColor : defaultTxt;
  const hoverIcon = iconColor ? iconColor : hoverTxt;
  const activeIcon = iconColor ? iconColor : activeTxt;
  const disabledIcon = disabledTxt;

  const colors = {
    button: {
      default: defaultBtn,
      hover: hoverBtn,
      active: activeBtn,
      disabled: disabledBtn,
      focus: hoverBtn,
    },
    border: {
      default: defaultBorder,
      hover: hoverBorder,
      active: activeBorder,
      disabled: disabledBorder,
      focus: focusBorder,
    },
    text: {
      default: defaultTxt,
      hover: hoverTxt,
      active: activeTxt,
      disabled: disabledTxt,
      focus: hoverTxt,
    },
    icon: {
      default: defaultIcon,
      hover: hoverIcon,
      active: activeIcon,
      disabled: disabledIcon,
      focus: hoverIcon,
    }
  }

  return colors;
}

export default getColors;
