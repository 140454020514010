import styled from "styled-components";
import { PlayerControlPanelArgs, TooltipArgs } from "./types";


export const Background = styled.div<Pick<PlayerControlPanelArgs, 'borderRadius'>>`
  background-color: ${({ theme }) => theme.background.default};
  padding: ${({ theme }) => theme.spacing}px;
  border-radius: ${({ borderRadius }) => borderRadius};
`;


export const ContentsPanel = styled.div`
  margin: 5px 10px;
`;


export const TrackbarWrapper = styled.div`
  margin: 0 80px; 
  flex-grow: 1;
`;


export const Button = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;


export const Time = styled.div`
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.default};
  padding-top: 4px;
`;


export const PointedTime = styled.span<Pick<TooltipArgs, 'left'>>`
  position: absolute;  
  top: -37px;  
  left: ${({ left }) => left}px;
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.light};
  padding: 5px 8px 4px;
  background: hsla(0,0%,0%,.32);
  border-radius: 4px;
`;