import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, useRouteMatch } from "react-router-dom";
import { WithIcon } from "../../elements/";
import { colorToHsla } from "../../assistants/";
import { Icon } from "../../elements/";
import { TEXT_COLORS } from "../../const/";
import { useMenuState } from './menuContext';

const LinkText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: ${({ theme }) => theme.font.s};
  opacity: ${({ active, light }) => active ? 1 : light ? 1 : 0.64};
  padding: 0 10px;
`;

const I = styled.i`
  height: 24px;
  opacity: ${({ active }) => active ? 1 : 0.56};
`;

const StyledMenuItem = styled.div`
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  box-sizing: border-box;
  border-right: 2px solid transparent;
  color: ${({ theme, light }) => light ? theme.text.dark : theme.text.light};

  ${props => (props.onClick || props.href || props.to) && `
    &:hover {
      cursor: pointer;
      background: ${props.light ? "hsla(0,0%,0%,.02)" : "hsla(0,0%,100%,.08)"};

      ${!props.small && (`
        color: ${props.light ? props.theme.link.default : props.theme.text.light};
      `)}
    }

    &:hover ${LinkText} {
      opacity: 1;
    }

    &:focus ${LinkText} {
      opacity: 1;
    }

    &:hover ${I} {
      opacity: 1;
    }

    &:focus ${I} {
      opacity: 1;
    }

    &:hover path {
      fill: ${!props.small ? props.theme.icon.color.primary : (props.light ? props.theme.icon.color.dark : props.theme.icon.color.light)};
    }

    &:focus {
      outline: none;
      background: ${props.light ? "hsla(0,0%,0%,.04)" : "hsla(0,0%,100%,.08)"};

      ${!props.small && (`
        color: ${props.light ? props.theme.link.default : props.theme.text.light};
      `)}
    }
  `}

  ${props => props.active && `
    background: ${props.light ? "hsla(0,0%,0%,.06)" : "hsla(0,0%,100%,.12)"};
    border-color: ${props.theme.background.brand};

    &:hover {
      background: ${props.light ? "hsla(0,0%,0%,.08)" : "hsla(0,0%,100%,.16)"};
    }

    &:focus {
      background: ${props.light ? "hsla(0,0%,0%,.12)" : "hsla(0,0%,100%,.20)"};
    }
  `}
`;

function MenuItem(props) {
  const {
    text,
    icon,
    onClick,
    active,
    to,
    href,
    target,
    activeOnlyWhenExact,
    ...rest
  } = props;

  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });

  const { light, small } = useMenuState();

  return (
    <StyledMenuItem target={target} as={to ? Link : href && "a"} to={to} href={href} active={active || match} small={small} light={light} onClick={onClick} {...rest}>
      {icon && (
        <I active={active || match}>
          <Icon icon={icon} size="m" color={light ? "dark" : "light"} />
        </I>
      )}
      {!small && (
        <LinkText light={light} active={active || match}>
          <WithIcon icon={target ? "link" : ""} size="xxs" color={light ? TEXT_COLORS.default : TEXT_COLORS.defaultLight}>
            {text}
          </WithIcon>
        </LinkText>
      )}
    </StyledMenuItem>
  );
}


MenuItem.defaultProps = {
  icon: "",
  text: undefined,
  onClick: undefined,
  active: false,
  to: undefined,
  href: undefined,
  activeOnlyWhenExact: undefined,
  target: "",
};

export default MenuItem;
