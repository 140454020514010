import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { ICON_SIZE } from '../../const/';

export const ICON = {
  dashboard: 'M9.718 13c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v4.436c0 .446-.046.607-.134.77a.909.909 0 01-.378.378c-.163.088-.324.134-.77.134H5.282c-.446 0-.607-.046-.77-.134a.909.909 0 01-.378-.378c-.088-.163-.134-.324-.134-.77v-4.436c0-.446.046-.607.134-.77a.909.909 0 01.378-.378c.163-.088.324-.134.77-.134h4.436zm9 0c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v4.436c0 .446-.046.607-.134.77a.909.909 0 01-.378.378c-.163.088-.324.134-.77.134h-4.436c-.446 0-.607-.046-.77-.134a.909.909 0 01-.378-.378c-.088-.163-.134-.324-.134-.77v-4.436c0-.446.046-.607.134-.77a.909.909 0 01.378-.378c.163-.088.324-.134.77-.134h4.436zM9 15H6v3h3v-3zm9 0h-3v3h3v-3zM9.718 4c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v4.436c0 .446-.046.607-.134.77a.909.909 0 01-.378.378c-.163.088-.324.134-.77.134H5.282c-.446 0-.607-.046-.77-.134a.909.909 0 01-.378-.378c-.088-.163-.134-.324-.134-.77V5.282c0-.446.046-.607.134-.77a.909.909 0 01.378-.378c.163-.088.324-.134.77-.134h4.436zm9 0c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v4.436c0 .446-.046.607-.134.77a.909.909 0 01-.378.378c-.163.088-.324.134-.77.134h-4.436c-.446 0-.607-.046-.77-.134a.909.909 0 01-.378-.378c-.088-.163-.134-.324-.134-.77V5.282c0-.446.046-.607.134-.77a.909.909 0 01.378-.378c.163-.088.324-.134.77-.134h4.436zM9 6H6v3h3V6zm9 0h-3v3h3V6z',
  edge: 'M17.154 3c1.337 0 1.822.14 2.311.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v10.31c0 1.336-.14 1.821-.4 2.31a2.726 2.726 0 01-1.135 1.134c-.489.262-.974.401-2.31.401H6.844c-1.336 0-1.821-.14-2.31-.4A2.726 2.726 0 013.4 19.464c-.262-.489-.401-.974-.401-2.31V6.844c0-1.336.14-1.821.4-2.31A2.726 2.726 0 014.536 3.4C5.024 3.139 5.509 3 6.845 3h10.31zM19 13H5v3.436c0 .892.093 1.215.267 1.54.174.327.43.583.756.757.301.16.6.252 1.345.265l.196.002h8.872c.892 0 1.215-.093 1.54-.267.327-.174.583-.43.757-.756.16-.301.252-.6.265-1.345l.002-.196V13zm-7 2v2H7v-2h5zm4 0a1 1 0 110 2 1 1 0 010-2zm.436-10H7.564c-.892 0-1.215.093-1.54.267-.327.174-.583.43-.757.756-.16.301-.252.6-.265 1.345L5 7.564V11h14V7.564c0-.892-.093-1.215-.267-1.54a1.817 1.817 0 00-.756-.757c-.326-.174-.65-.267-1.54-.267zM16 7a1 1 0 110 2 1 1 0 010-2zm-4 0v2H7V7h5z',
  events: 'M9 2v2h6V2h2v2h4v17H3V4h4V2h2zM7 6H5v13h14V6h-2v2h-2V6H9v2H7V6zm10 7v4h-4v-4h4z',
  exit: 'M19 3v18H9v-2h8V5H9V3h10zm-8.586 4.06l4.93 4.93.02.014-.003.003.003.003-.019.012-4.93 4.932L9 15.54 11.796 13H1v-2h10.79L9 8.474l1.414-1.414z',
  info: 'M13.411 2c2.659 0 3.867.325 5.008.935a6.36 6.36 0 012.646 2.646c.61 1.14.935 2.35.935 5.008v2.822c0 2.659-.325 3.867-.935 5.008a6.36 6.36 0 01-2.646 2.646c-1.14.61-2.35.935-5.008.935h-2.822c-2.659 0-3.867-.325-5.008-.935a6.36 6.36 0 01-2.646-2.646c-.61-1.14-.935-2.35-.935-5.008v-2.822c0-2.659.325-3.867.935-5.008a6.36 6.36 0 012.646-2.646C6.721 2.325 7.931 2 10.589 2h2.822zM10.41 4c-2.23 0-3.037.232-3.852.668a4.543 4.543 0 00-1.89 1.89c-.416.778-.646 1.55-.667 3.556L4 10.41v3.18c0 2.23.232 3.037.668 3.852a4.543 4.543 0 001.89 1.89c.778.416 1.55.646 3.556.667l.296.001h3.18c2.23 0 3.037-.232 3.852-.668a4.543 4.543 0 001.89-1.89c.416-.778.646-1.55.667-3.556L20 13.59v-3.18c0-2.23-.232-3.037-.668-3.852a4.543 4.543 0 00-1.89-1.89c-.778-.416-1.55-.646-3.556-.667L13.59 4h-3.18zm.59 6h2v8h-2v-6H9v-2h2zm2-4v2h-2V6h2z',
  list: 'M20 16v2H8v-2h12zM6 16v2H4v-2h2zm14-5v2H8v-2h12zM6 11v2H4v-2h2zm14-5v2H8V6h12zM6 6v2H4V6h2z',
  log: 'M12 16v2H4v-2h8zm6-4l2 2-4 4h-2v-2l4-4zm-2-1l-2 2H4v-2h12zm4-1l2 2-1 1-2-2 1-1zm-2-4v2H4V6h14z',
  path: 'M13 2v6.126a4.002 4.002 0 010 7.748V22h-2v-6.126a4.002 4.002 0 010-7.748V2h2zm-1 8a2 2 0 100 4 2 2 0 000-4z',
  persons: 'M17 14c2.835 0 5.23 2.108 6 4.999h-1.902c-.69-1.765-2.265-3-4.098-3-1.784 0-3.324 1.17-4.041 2.86.015.046.028.093.04.14H11l.04-.141-.029-.068C10.281 17.137 8.76 16 7 16 5.167 16 3.59 17.234 2.9 18.999H1c.77-2.891 3.165-5 6-5 2.044 0 3.858 1.096 5 2.79 1.141-1.694 2.956-2.79 5-2.79zM7 5a4 4 0 110 8 4 4 0 010-8zm10 0a4 4 0 110 8 4 4 0 010-8zM7 7a2 2 0 100 4 2 2 0 000-4zm10 0a2 2 0 100 4 2 2 0 000-4z',
  settings: 'M13.185 3l2.289.613-.59 2.007c.424.192.824.424 1.197.692l1.445-1.514 1.676 1.676-1.514 1.445c.268.373.5.773.692 1.196l2.007-.589.613 2.29-2.034.493a7.084 7.084 0 010 1.382l2.034.494-.613 2.289-2.007-.59a6.993 6.993 0 01-.692 1.197l1.514 1.445-1.676 1.676-1.445-1.514c-.373.268-.773.5-1.196.692l.589 2.007-2.29.613-.493-2.034a7.084 7.084 0 01-1.382 0L10.815 21l-2.289-.613.59-2.007a6.993 6.993 0 01-1.197-.692l-1.445 1.514-1.676-1.676 1.514-1.445a6.993 6.993 0 01-.692-1.196l-2.007.589L3 13.184l2.034-.493a7.084 7.084 0 010-1.382L3 10.815l.613-2.289 2.007.59c.192-.424.424-.824.692-1.197L4.798 6.474l1.676-1.676 1.445 1.514c.373-.268.773-.5 1.196-.692l-.589-2.007L10.816 3l.493 2.034a7.084 7.084 0 011.382 0L13.185 3zM12 7a5 5 0 100 10 5 5 0 000-10zm0 3a2 2 0 110 4 2 2 0 010-4z',
  close: "M18.364 7.05L13.414 12l4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636l4.95 4.95 4.95-4.95 1.414 1.414z",
  mark: "M18.778 6l1.414 1.414L8.88 18.728l-.004-.004v.002L4 13.95l1.414-1.414 3.414 3.413L18.778 6z",
  add: "M13 4v7h7v2h-7v7h-2v-7H4v-2h7V4h2z",
  trash: "M8 8v8.718c0 .446.046.607.134.77.087.163.215.291.378.378.142.077.284.122.616.132l.154.002h5.436c.446 0 .607-.046.77-.134a.909.909 0 00.378-.378c.077-.142.122-.284.132-.616l.002-.154V8H8zm6.626-3.497c-.047-.186-.079-.25-.127-.313a.45.45 0 00-.183-.143.59.59 0 00-.233-.045L13.98 4h-3.883c-.186 0-.254.014-.326.044a.449.449 0 00-.18.134.596.596 0 00-.105.205l-.03.094L9 6H7l.568-2.045c.203-.73.334-.978.528-1.223.195-.246.435-.429.724-.55C9.109 2.06 9.383 2 10.14 2h3.72c.757 0 1.031.06 1.32.182.289.121.53.304.724.55.194.245.325.494.528 1.223L17 6h3v2h-2v9.436c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H8.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 01-.757-.756c-.174-.326-.267-.65-.267-1.54V8H4V6h11l-.374-1.497z",
  api: "M7 4v2H5v4c0 1-.667 1.667-2 2 1.333.333 2 1 2 2v4h2v2H5c-1.5 0-2-.5-2-2v-4c0-.5-1-1-2-1v-2c1 0 2-.5 2-1V6c0-1.5.5-2 2-2h2zm12 0c1.44 0 1.958.46 1.998 1.825L21 6v4c0 .472.892.944 1.834.995L23 11v2c-.944 0-1.889.446-1.99.917L21 14v4c0 1.44-.46 1.958-1.825 1.998L19 20h-2v-2h2v-4c0-1 .667-1.667 2-2-1.26-.315-1.924-.927-1.994-1.836L19 10V6h-2V4h2zm-6 9v2H7v-2h6zm4-4v2H7V9h10z",
  next: "M13.775 4.5L20.75 12l-6.975 7.5-1.414-1.435 4.974-5.05H3.25v-2.03l14.085-.001-4.974-5.049L13.775 4.5z",
  back: "M10.225 4.5L3.25 12l6.975 7.5 1.414-1.435-4.974-5.05H20.75v-2.03l-14.085-.001 4.974-5.049L10.225 4.5z",
  dropdown: "M15 12L10.5 6 9 7 13 12 9 17 10.5 18z",
  dropdownActive: "M6 10.297L12 15 18 10.297 17.046 9.002 12 12.957 6.951 9z",
  license: "M14.023 1l1.64 1.817 2.394.512.513 2.394 1.817 1.641-.754 2.33.754 2.329-1.817 1.64-.513 2.394-1.864.399v7.237l-4.5-3-4.5 3v-7.237l-1.864-.399-.512-2.394L3 12.023l.753-2.33L3 7.364l1.817-1.64.512-2.395 2.394-.512L9.364 1l2.33.753L14.022 1zm-2.33 15.693l-2.5 1.5v1.5l2.5-1.5 2.5 1.5v-1.5l-2.5-1.5zm1.553-12.795l-1.553.502-1.553-.502-1.093 1.211-1.596.342-.342 1.596L5.898 8.14 6.4 9.693l-.502 1.553 1.211 1.094.342 1.596 1.596.342 1.093 1.21 1.553-.501 1.553.502 1.094-1.211 1.596-.342.342-1.596 1.21-1.094-.501-1.553.502-1.553-1.211-1.093-.342-1.596-1.596-.342-1.094-1.211zm-.585 2.795L12.4 9.03l2.367-.66.21 1.6-2.159.152 1.416 1.885-1.441.768-.99-1.986-.87 1.974-1.498-.756 1.403-1.885-2.146-.165.248-1.587 2.317.66-.26-2.336h1.663z",
  person: "M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 15c-1.51 0-2.844.836-3.654 2.117A7.952 7.952 0 0012 20a7.966 7.966 0 003.652-.88C14.844 17.835 13.51 17 12 17zm0-13a8 8 0 00-5.19 14.087C7.933 16.224 9.84 15 12 15c2.16 0 4.066 1.225 5.19 3.087A8 8 0 0012 4zm0 2a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z",
  email: "M22 4v16H2V4h20zM3.999 6.927L4 18h16l-.001-11.072L12 13.5 3.999 6.927zM18.018 6H5.98L12 11.05 18.018 6z",
  upload: "M5 17v2h14v-2h2v4H3v-4h2zm6.996-14l.013.02 4.931 4.93-1.414 1.414L13 6.575v10.789h-2l-.001-10.796L8.46 9.364 7.046 7.95l4.931-4.931L11.99 3l.003.003.003-.003z",
  search: "M19.581 21L21 19.586l-6.094-6.074A6 6 0 0010.041 4H10a6 6 0 100 12h.04c1.28 0 2.465-.4 3.439-1.082L19.581 21zm-9.547-7h-.027a4 4 0 01-.2-7.995l.2-.005h.027a4 4 0 110 8z",
  comparison: "M3 20V4h18v16H3zM19 6h-6v12h6V6zm-8 0H5v12h6V6z",
  link: "M11 4v2H6v12h12v-5h2v7H4V4h7zm10-1v7h-2l-.001-3.586-6.949 6.95-1.414-1.414L17.585 5H14V3h7z",
  help: "M13.411 2c2.659 0 3.867.325 5.008.935a6.36 6.36 0 012.646 2.646c.61 1.14.935 2.35.935 5.008v2.822c0 2.659-.325 3.867-.935 5.008a6.36 6.36 0 01-2.646 2.646c-1.14.61-2.35.935-5.008.935h-2.822c-2.659 0-3.867-.325-5.008-.935a6.36 6.36 0 01-2.646-2.646c-.61-1.14-.935-2.35-.935-5.008v-2.822c0-2.659.325-3.867.935-5.008a6.36 6.36 0 012.646-2.646C6.721 2.325 7.931 2 10.589 2h2.822zm.18 2H10.41c-2.23 0-3.037.232-3.852.668a4.543 4.543 0 00-1.89 1.89c-.416.778-.646 1.55-.667 3.556L4 10.41v3.18c0 2.23.232 3.037.668 3.852a4.543 4.543 0 001.89 1.89c.778.416 1.55.646 3.556.667l.296.001h3.18c2.23 0 3.037-.232 3.852-.668a4.543 4.543 0 001.89-1.89c.416-.778.646-1.55.667-3.556L20 13.59v-3.18c0-2.23-.232-3.037-.668-3.852a4.543 4.543 0 00-1.89-1.89c-.778-.416-1.55-.646-3.556-.667L13.59 4h-3.18zm-1.767 10.562c.823 0 1.163.457 1.163 1.053v.332c0 .596-.34 1.053-1.163 1.053-.824 0-1.164-.457-1.164-1.053v-.332c0-.596.34-1.053 1.164-1.053zM11.954 7C13.784 7 15 8.163 15 9.853c0 1.551-1.059 2.549-2.288 2.756v.998h-1.83v-2.12c1.373-.055 2.066-.443 2.066-1.468v-.194c0-.706-.432-1.08-1.06-1.08-.679 0-1.136.499-1.293 1.177L9 9.216C9.3 8.108 10.176 7 11.954 7z",
  edit: "M21 3v2H8.846c-1.337 0-1.822.14-2.311.4A2.726 2.726 0 005.4 6.536c-.245.458-.383.913-.4 2.07L5 8.845v6.31c0 1.336.14 1.821.4 2.31.262.49.646.873 1.135 1.134.458.245.913.383 2.07.4l.24.001H21v2H9.41c-2.23 0-3.037-.232-3.852-.668a4.543 4.543 0 01-1.89-1.89C3.232 17.627 3 16.82 3 14.59V9.41c0-2.23.232-3.037.668-3.852a4.543 4.543 0 011.89-1.89C6.373 3.232 7.18 3 9.41 3H21zm-8 7l2 2-4 4H9v-2l4-4zm2-2l2 2-1 1-2-2 1-1z",
  divi: "M12 2a9.99 9.99 0 018.3 4.42l-6.8 3.925a1 1 0 00-.493.749L13 11.21v10.423a1 1 0 00.05.31 9.909 9.909 0 01-1.904.02.96.96 0 00.054-.32V11.21a1 1 0 00-.496-.864l-6.925-4.04A9.989 9.989 0 0112 2zM2.81 8.05l6.411 3.738v9.82C5.05 20.405 2 16.559 2 12c0-1.403.289-2.738.81-3.95zm18.436.133A9.97 9.97 0 0122 12c0 4.477-2.942 8.267-7 9.542v-9.754l6.246-3.605zM12 4a2 2 0 100 4 2 2 0 000-4z",
  nuitrack: "M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm4.81 8.995l-.118.004c-.419.04-.678.34-.778.899l-1.159 7.008-.025.145c-.082.558.069.87.453.938.416.072.719-.204.908-.83l1.482-6.9.023-.15c.087-.677-.133-1.046-.66-1.106l-.125-.008zM12.967 4l-.112.006c-.428.042-.658.329-.688.86l-.093 13.94.003.146c.035.66.372 1.004 1.01 1.034.682.032 1.061-.262 1.135-.883L16.113 6.39l.02-.136c.06-.527-.135-.84-.586-.937-.488-.107-.798.125-.93.694l-1.234 12.115-.008.081c-.025.149-.093.224-.206.224-.132 0-.204-.102-.218-.305l.903-13.018.009-.143c.017-.598-.242-.918-.778-.96L12.965 4zm-2.774 1.316c-.493 0-.746.382-.756 1.146l.389 9.245c-1.669-2.818-2.856-4.06-3.56-3.724-.706.336 0 2.818 2.115 7.447.187.37.408.486.664.344.255-.141.311-.4.167-.778l-1.239-2.79c-.152-.333-.126-.552.08-.658.205-.106.402 0 .59.316l1.716 3.34c.197.522.456.782.778.782.321 0 .475-.26.462-.781l-.562-12.743c-.07-.764-.35-1.146-.844-1.146zm7.158 3.126l-.248-.005c-.422.039-.654.334-.703.787-.058.535.022 1.117.593 1.211.57.095.934-.44.994-.912.06-.472-.056-1.014-.636-1.081l-.129-.01z",
  pen: "M17.926 10.222L20 8.148 15.852 4l-2.074 2.074 4.148 4.148zM8.148 20l8.296-8.296L14.5 10 7 17.5l-.5-.5L14 9.5l-1.704-1.944L4 15.852V20h4.148z",
  video: "M17.154 3c1.337 0 1.822.14 2.311.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v10.31c0 1.336-.14 1.821-.4 2.31a2.726 2.726 0 01-1.135 1.134c-.489.262-.974.401-2.31.401H6.844c-1.336 0-1.821-.14-2.31-.4A2.726 2.726 0 013.4 19.464c-.262-.489-.401-.974-.401-2.31V6.844c0-1.336.14-1.821.4-2.31A2.726 2.726 0 014.536 3.4C5.024 3.139 5.509 3 6.845 3h10.31zm-.077 2H6.923c-.669 0-.911.07-1.156.2-.244.131-.436.323-.567.567-.118.223-.187.443-.198.984L5 6.923v10.154c0 .669.07.911.2 1.156.131.244.323.436.567.567.223.118.443.187.984.198l.172.002h10.154c.669 0 .911-.07 1.156-.2.244-.131.436-.323.567-.567.118-.223.187-.443.198-.984l.002-.172V6.923c0-.669-.07-.911-.2-1.156a1.363 1.363 0 00-.567-.567c-.245-.13-.487-.2-1.156-.2zM10.5 8.434a.5.5 0 01.277.084l4.599 3.066a.5.5 0 010 .832l-4.599 3.066a.5.5 0 01-.777-.416V8.934a.5.5 0 01.5-.5z",
  download: "M21 21H3v-4h2v2h14v-2h2v4zm-4.06-8.586l-4.93 4.93-.014.02-.003-.003-.003.003-.012-.019-4.932-4.93L8.46 11 11 13.796V3h2v10.79L15.526 11l1.414 1.414z",
  anonymous: "M12 2c3.667 0 6.667.833 9 2.5v8c0 2.5-4.5 9.5-9 9.5s-9-7-9-9.5v-8C5.333 2.833 8.333 2 12 2zm0 2c-2.71 0-4.95.602-6.724 1.805L5 6v6.4c0 1.809 2.864 6.563 6 7.455V18h2v1.855c3.068-.873 5.873-5.438 5.996-7.33L19 12.4V6c-1.815-1.333-4.148-2-7-2zm0 9l1.5 1.5H15l1-.5.5 1.5-1 .5H13l-1-1-1 1H8.5l-1-.5L8 14l1 .5h1.5L12 13zM9 9c.736 0 1.403.333 2 1-.597.667-1.264 1-2 1s-1.403-.333-2-1c.597-.667 1.264-1 2-1zm6 0c.736 0 1.403.333 2 1-.597.667-1.264 1-2 1s-1.403-.333-2-1c.597-.667 1.264-1 2-1zm3-1c-1.333-.667-2.333-.667-3 0-1 1-2 1-2 .2s.583-1.1 2.02-1.2c.959-.067 1.952.266 2.98 1zM8.98 7C10.596 7 11 7.8 11 8.2c0 .4-1 .8-2-.2-.667-.667-1.667-.667-3 0 .91-.667 1.902-1 2.98-1z",
  star: "M12 2l3.104 6.728 7.358.873-5.44 5.03 1.444 7.268L12 18.28 5.534 21.9l1.444-7.268L1.538 9.6l7.358-.873L12 2zm0 5l-1.693 3.67-4.013.476L9.26 13.89l-.788 3.964L12 15.88l3.527 1.974-.788-3.964 2.967-2.744-4.013-.476L12 7z",
  workspace: "M12 16c2.835 0 5.23 2.108 6 4.999h-1.901c-.69-1.765-2.266-3-4.099-3-1.833 0-3.409 1.235-4.099 3h-1.9c.769-2.891 3.164-5 5.999-5zm9-13v13l-3.416-.001a7.095 7.095 0 00-1.38-.99l.17.099a6.02 6.02 0 00.823-1.107L19 14V5H5v9l1.803.001c.232.4.509.772.824 1.107a6.918 6.918 0 00-1.211.891L3 16V3h18zm-9 4a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z",
  biometry: "M22 4v16H2V4h20zM12 16a4.002 4.002 0 01-3.874-3H4v-2h4.126a4.002 4.002 0 017.748 0h4.125L20 6H4v12h16l-.001-5h-4.125A4.002 4.002 0 0112 16zm0-6a2 2 0 100 4 2 2 0 000-4z",
  lock: "M12.092 2c.866 0 1.547.162 2.117.467a3.18 3.18 0 011.324 1.324c.305.57.467 1.251.467 2.117V9h.436c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v6.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H7.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 01-.757-.756c-.174-.326-.267-.65-.267-1.54v-6.873c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267H8V5.908c0-.866.162-1.547.467-2.117a3.18 3.18 0 011.324-1.324C10.36 2.162 11.042 2 11.908 2h.184zm4.267 9H7.641c-.223 0-.304.023-.385.067a.454.454 0 00-.19.189.59.59 0 00-.064.285l-.002.1v6.718c0 .223.023.304.067.385a.454.454 0 00.189.19.59.59 0 00.285.064l.1.002h8.718c.223 0 .304-.023.385-.067a.454.454 0 00.19-.189.59.59 0 00.064-.285l.002-.1v-6.718c0-.223-.023-.304-.067-.385a.454.454 0 00-.189-.19c-.081-.043-.162-.066-.385-.066zM12 13a1 1 0 011 1v2a1 1 0 01-2 0v-2a1 1 0 011-1zm0-9a2 2 0 00-1.995 1.85L10 6v3h4V6a2 2 0 00-2-2z",
  business: "M14.436 3c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54V7h4v11.436c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H5.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 01-.757-.756c-.174-.326-.267-.65-.267-1.54V7h4V5.564c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267h4.873zM5 18.359c0 .223.023.304.067.385a.454.454 0 00.189.19.59.59 0 00.285.064l.1.002h12.718c.223 0 .304-.023.385-.067a.454.454 0 00.19-.189.59.59 0 00.064-.285l.002-.1V13h-4l-1 1h-4l-1-1H5v5.359zM19 9H5v2h5l1 1h2l1-1h5V9zm-4.641-4H9.641c-.223 0-.304.023-.385.067a.454.454 0 00-.19.189.59.59 0 00-.064.285L9 5.64V7h6V5.641c0-.223-.023-.304-.067-.385a.454.454 0 00-.189-.19.59.59 0 00-.285-.064L14.36 5z",
  camera: "M15.214 4L16.5 6H21v14H3V6h4.5l1.286-2h6.428zM14 6h-4L8.5 8H5v10h14V8h-3.5L14 6zm-2 3a4 4 0 110 8 4 4 0 010-8zm0 2a2.001 2.001 0 10.002 4.002A2.001 2.001 0 0012 11zm5-2a1 1 0 110 2 1 1 0 010-2z",
  warning: "M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 100 16 8 8 0 000-16zm0 10a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0-7a1 1 0 011 1v4a1 1 0 01-2 0V8a1 1 0 011-1z",
  bullet: "M12 7a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6z",
  windows: "M22 2v20L2 20V4l20-2zm-2 11h-8v5.5l8 .5v-6zM4 13v5l6 .375V13H4zm16-8l-8 .5V11h8V5zM4 11h6V5.625L4 6v5z",
  linux: "M16.532 16.656c.507.133.682-.696 1.397-.404.715.291.376.868.518 1.28.142.412 1.013.266 1.136 1.068.122.803-1.435 1.186-1.888 1.88-.452.692-1.679 2.036-2.696 1.312-1.018-.725-.662-1.923-.395-2.706.268-.784-.37-2.637.499-3.013.869-.376.923.45 1.43.583zm-9.83-.368l.151.016c.799.146.654 1.743 1.162 2.397.508.653 1.38 2.25.363 2.977-1.017.726-2.033 0-2.687-.509-.653-.508-2.614-.508-2.686-1.452-.073-.944.726-.726 1.016-1.162.29-.435-.435-.87.073-1.452.508-.58.944-.072 1.38-.072.377 0 .536-.655 1.092-.735l.136-.008zm6.105 3.139c-.193.871-.242 1.452-.145 1.742h-2.251c.097-.726.048-1.307-.145-1.742.145.097.58.145 1.307.145.726 0 1.137-.048 1.234-.145zM11.137 2c2.687 0 3.34 2.178 3.63 4.575.291 2.396 4.067 5.373 4.067 8.35 0 .072-1.38-.871-2.251-.218-.726-.339-1.259-.339-1.597 0-.242-1.21-.533-2.178-.872-2.904-.339-.727-.847-1.525-1.525-2.397-.532.63-1.016.944-1.452.944-.436 0-.895-.193-1.38-.58-.726 1.016-1.185 1.839-1.38 2.468-.193.63-.41 1.5-.653 2.614-.435-.193-.823-.29-1.161-.29-.509 0-1.017.29-1.017.363-.048-1.743.823-3.897 2.614-6.463a13.387 13.387 0 01-.218-2.468C7.942 4.687 8.451 2 11.137 2zM9.753 3.882c-1.017 0-1.09 3.454-.145 4 0 0 .159-.265.508-.546.091-.074.26-.165.508-.273.097-2.12-.193-3.181-.871-3.181zm2.354-.073c-.82 0-1.142 1.085-.966 3.256.234.062.468.155.703.279.351.186.703.465.703.465.79-.558.79-4-.44-4zM9.825 5.5c.241 0 .364.333.364.654 0 .32-.146.58-.364.58-.217 0-.435-.26-.435-.58 0-.321.195-.654.435-.654zm2.106 0c.24 0 .436.333.436.654 0 .32-.218.58-.436.58s-.363-.26-.363-.58c0-.321.123-.654.363-.654z",
  android: "M16.86 2.271a1 1 0 01.246 1.393l-.698.995c.94.95 1.533 2.307 1.588 4.071L18 9v8a2 2 0 01-2 2v3a1 1 0 01-2 0v-3h-4v3a1 1 0 01-2 0v-3a2 2 0 01-2-2V9c0-1.897.604-3.343 1.592-4.34l-.698-.996a1 1 0 011.56-1.245l.078.098.715 1.021C10.072 3.18 11.007 3 12 3c.992 0 1.928.18 2.753.538l.715-1.021a1 1 0 011.392-.246zM16 11H8v5a1 1 0 00.883.993L9 17h6a1 1 0 00.993-.883L16 16v-5zm4-2a1 1 0 011 1v5a1 1 0 01-2 0v-5a1 1 0 011-1zM4 9a1 1 0 011 1v5a1 1 0 01-2 0v-5a1 1 0 011-1zm8-4C9.79 5 8 6 8 9h8c0-3-1.79-4-4-4zm-2 1a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2z",
  apple: "M16.765 6C18.176 6 19.588 7.067 21 9.2c-1.754.66-2.634 1.81-2.64 3.45 0 1.778.88 3.116 2.64 4.017C19.235 20.222 17.647 22 16.235 22c-2.117 0-2.117-1.067-3.706-1.067-1.588 0-1.588 1.067-3.705 1.067C6.706 22 3 16.667 3 12.4 3 8.133 6.176 6 8.294 6c2.118 0 2.647 1.067 4.235 1.067C14.118 7.067 14.647 6 16.765 6zm.369 2c-1.639 0-3.183 1.09-4.412 1.09C11.492 9.09 9.948 8 8.309 8 6.67 8 5 9.636 5 12.364 5 15.09 7.206 20 9.412 20c1.103 0 1.655-1.09 3.31-1.09 1.654 0 2.206 1.09 3.309 1.09.863 0 1.71-.979 2.826-2.732.01-.013-2.406-1.672-2.406-4.4 0-2.727 1.844-4.102 1.815-4.086-.26-.323-.833-.782-1.132-.782zM12 5.5c-.222-1 0-2 .667-3 .666-1 1.61-1.5 2.833-1.5.333 1 .167 2-.5 3s-1.667 1.5-3 1.5z",
  file: "M14.5 3L19 7.5V21H5V3h9.5zM12 5H7v14h10v-9h-4a1 1 0 01-1-1V5zm2 .234V8h2.541L14 5.234z",
  upgrade: "M12 14l5 5-1.5 1.5L12 17l-3.5 3.5L7 19l5-5zm0-10l7 7-1.5 1.5L12 7l-5.5 5.5L5 11l7-7zM7.5 16.5L6 15l6-6 6 6-1.5 1.5L12 12l-4.5 4.5z",
  nuitrackHand: "M13.871 4.02l-.594 13.666c-.045.575.247.287.333 0l1.513-12.438c.221-1.073 1.822-.575 1.751.335l-1.122 6.404-1.195 7.886c-.379 1.714-2.455 1.275-2.457 0l-.17-8.288V4.021c.13-1.41 1.855-1.312 1.941 0zm-3.3 1.386l.77 15.046c-.198.512-.944.818-1.452.037-.34-.52-1.152-1.966-2.439-4.338-.375-.772-.757-.764-.32.212.578 1.598.955 2.752 1.133 3.462C8.53 20.89 7.36 21.13 7 20.24c-.781-2.261-1.398-4.207-1.85-5.838-.679-2.446 1.136-2.846 1.98-1.71l2.34 3.458-.961-10.533c0-1.258 2.027-1.613 2.063-.212zm7.91 6.876l-1.427 7.54c-.483 1.684-2.06 1.29-1.761 0l1.424-7.8c.342-1.737 2.07-1.353 1.764.26zm.479-2.955c-.412 1.636-1.868 1.251-1.707-.28.174-1.704 2.042-1.294 1.707.28z",
  faceSdk: "M16 21v-.001h-.537A3.998 3.998 0 0011.998 19c-1.48 0-2.773.804-3.464 2L8 20.999V21H5.564c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 01-.757-.756c-.16-.301-.252-.6-.265-1.345L3 18.436V16h2v3h2.526a5.985 5.985 0 014.472-2c1.777 0 3.374.772 4.472 2H19v-3h2v2.436c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H16zM12 8a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4zM8 3v2H5v3H3V5.564c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267H8zm10.436 0c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.16.301.252.6.265 1.345l.002.196V8h-2V5h-3V3h2.436z",
  faceMachine: "M7.001 21.946v.108L7 22l.001-.054zM12 17a5.001 5.001 0 014.584 3h-2.348c-.55-.614-1.347-1-2.236-1-.889 0-1.687.386-2.236 1H7.416A5.001 5.001 0 0112 17zm0-13a6 6 0 015.996 5.775L18 10c2.658 0 5 2.188 5 5 0 2.563-1.947 4.609-4.305 4.95a6.975 6.975 0 00-.973-1.983C19.519 17.759 21 16.534 21 15c0-1.63-1.674-2.912-3.621-2.996L17.176 12l-1.106.002c.014-.636.02-1.303.02-2.002a4 4 0 10-8 0c0 .184-.002.454-.01.812l-.003.186L6.5 11l-.192.005a3.5 3.5 0 00-.045 6.987c-.401.57-.718 1.203-.934 1.882a5.5 5.5 0 01.753-10.858A5.999 5.999 0 0112 4zm0 6a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z",
  key: "M15 3a6 6 0 11-1.742 11.743L13 15l-1.5.5L11 17l-1.5.5L9 19l-1.5.5L7 21H3v-4l6.163-6.603A6 6 0 0115 3zm0 2a4 4 0 00-3.727 5.456l.095.222L5 17.5V19h.5l.5-1 1.5-.5L8 16l1.5-.5.5-1.5 2-.5 1.023-1.022A4 4 0 1015 5zm1 1.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3z",
  documents: "M21 3v16.2L19 21l-2-2-2 2-2-2-2 2-2-2-2 2-2-2-2 2V3h18zm-2 2H5v14l3-2 1 2 3-2 1 2 3-2 1 2 2-2V5zm-7 6v2H7v-2h5zm5-4v2H7V7h10z",
  pause: "M19 5v14h-6V5h6zm-2 1.75h-2v10.5h2V6.75zM11 5v14H5V5h6zM9 6.75H7v10.5h2V6.75z",
  openMenu: "M15 16v2H3v-2h12zm4-8l3 4-3 4h-2l3-4-3-4h2zm-4 3v2H3v-2h12zm0-5v2H3V6h12z",
  closeMenu: "M21 16v2H9v-2h12zM7 8l-3 4 3 4H5l-3-4 3-4h2zm14 3v2H9v-2h12zm0-5v2H9V6h12z",
  nextArrow: "M13.25 20.25l1.5 1.5 6-6-6-6-1.5 1.5 4.5 4.5-4.5 4.5z",
  prevArrow: "M18.75 20.25l-1.5 1.5-6-6 6-6 1.5 1.5-4.5 4.5 4.5 4.5z",
  refresh: "M3 4h2l-.001 2.344a9 9 0 11-1.993 5.986h2.252a6.75 6.75 0 101.305-4.331L9 7.999v2l-4-.001V10H3V4z",
  listSearch: "M14.962 10a3.968 3.968 0 013.961 3.975c0 .729-.195 1.412-.536 1.999L21.414 19 20 20.414l-3.018-3.018a3.933 3.933 0 01-2.02.555A3.968 3.968 0 0111 13.975 3.968 3.968 0 0114.962 10zM4 16h5.341c.264.745.67 1.423 1.187 2H4v-2h5.341zm10.962-4.012c-1.094 0-1.981.89-1.981 1.987 0 1.098.887 1.988 1.98 1.988 1.094 0 1.981-.89 1.981-1.988a1.984 1.984 0 00-1.98-1.987zM4 11l5.803-.001a5.96 5.96 0 00-.72 2L4 13v-2l5.803-.001zm14-5v2H4V6h14z",
  addToList: "M13 13.5v3h3v2h-3v3h-2v-3H8v-2h3v-3h2zM5.5 8a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm14.5.5v2H9v-2h11zM5.5 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm14.5.5v2H9v-2h11z",
  removeFromList: "M16 16.5v2H8v-2h8zM5.5 8a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm14.5.5v2H9v-2h11zM5.5 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm14.5.5v2H9v-2h11z",
  minus: "M4 11L20 11 20 13 4 13z",
  paly: "M6 4l13 8-13 8V4zm2 4v8l7-4-7-4z",
  analytics: "M4 20v-8h5V8h5V4h7v16H4zm10-2v-8h-3v8h3zm-5-4H6v4h3v-4zm10-8h-3v12h3V6z",
  show: "M12 5c3.682 0 7.015 2.333 10 7-2.985 4.667-6.318 7-10 7-3.682 0-7.015-2.333-10-7 2.985-4.667 6.318-7 10-7zm0 2c-2.946 0-5.612 1.667-8 5 2.388 3.333 5.054 5 8 5 2.946 0 5.612-1.667 8-5-2.388-3.333-5.054-5-8-5zm0 2a3 3 0 110 6 3 3 0 010-6z",
  hide: "M5.636 4.222l14.142 14.142-1.414 1.414-1.976-1.974C14.985 18.6 13.522 19 12 19c-3.682 0-7.015-2.333-10-7 1.253-1.96 2.567-3.507 3.943-4.644l-1.721-1.72 1.414-1.414zM4 12c2.388 3.333 5.054 5 8 5a7.582 7.582 0 002.977-.607l-1.684-1.685a3 3 0 01-4-4L7.22 8.634C6.096 9.45 5.023 10.573 4 12zm8-7c3.682 0 7.015 2.333 10 7-.932 1.457-1.898 2.686-2.897 3.688l-1.23-1.229c.733-.682 1.441-1.502 2.127-2.459-2.388-3.333-5.054-5-8-5-.49 0-.972.046-1.447.138L8.97 5.556A8.515 8.515 0 0112 5z",
  nuitrackAnalytics: "M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 2a8 8 0 100 16 8 8 0 000-16zm1.337 2.68l-.425 9.11c-.032.384.176.192.238 0L12.23 5.5c.159-.716 1.302-.383 1.252.223l-.802 4.27-.854 5.257c-.27 1.142-1.753.85-1.754 0L9.95 9.723V4.681a.693.693 0 011.386 0zm-2.357.924l.549 10.03c-.141.342-.674.546-1.037.026-.242-.347-.823-1.311-1.742-2.893-.268-.514-.54-.51-.23.142.414 1.065.684 1.834.81 2.308.191.71-.645.87-.902.277a63.73 63.73 0 01-1.322-3.892c-.484-1.631.812-1.897 1.415-1.14l1.672 2.305-.687-7.022c0-.838 1.448-1.075 1.474-.14zm5.65 4.584l-1.02 5.026c-.345 1.123-1.47.861-1.258 0l1.018-5.2c.243-1.157 1.477-.901 1.26.174zm.341-1.97c-.294 1.09-1.334.834-1.219-.187.125-1.136 1.459-.862 1.22.187z",

}

const StyledIcon = styled.svg`
  display: inline-block;
`;

const Path = styled.path`
  fill: ${({ theme, color }) => theme.icon.color[color] ? theme.icon.color[color] : color};
`;

function Icon(props) {
  const {
    icon,
    color,
    size,
    ...other
  } = props;
  return (
    <StyledIcon viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" x="0" y="0" width={ICON_SIZE[size]} height={ICON_SIZE[size]} {...other}>
      <Path
        color={color}
        fillRule="evenodd"
        d={ICON[icon]}
      />
    </StyledIcon>
  )
}

Icon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
}

Icon.defaultProps = {
  color: "default",
  size: "s",
  icon: "",
}

export default Icon;
