import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { BORDER_COLORS, TEXT_COLORS } from "../../const";

import { Typography, Button } from '../../elements/';

const Page = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99998;

  .drawer_bg-enter,
  .drawer_bg-appear {
    opacity: 0;
  }
  .drawer_bg-enter-active,
  .drawer_bg-appear-active {
    opacity: 1;
    transition: opacity 250ms;
  }

  .drawer_bg-exit {
    opacity: 1;
  }
  .drawer_bg-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }

  .drawer_content-enter,
  .drawer_content-appear {
    transform: translateX(100%);
  }
  .drawer_content-enter-active,
  .drawer_content-appear-active {
    transform: translateX(0);
    transition: transform 250ms;
  }

  .drawer_content-exit {
    transform: translateX(0);
  }
  .drawer_content-exit-active {
    transform: translateX(100%);
    transition: transform 250ms;
  }
`;

const Background = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(0, 0%, 0%, 0.72);
  z-index: 1;
`;

const Main = styled.div`
  display: block;
  height: 100vh;
  width: 100%;
  max-width: ${(props) => props.width}px;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  background-color: #fff;
`;

const Section = styled.section`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-flow: column nowrap;
  background: #fff;
  height: 100%;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  padding: 8px 12px;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  align-items: start;
  padding: 0 8px;
`;

const Buttons = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > button {
    margin: 0 4px;
  }
`;

const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid ${BORDER_COLORS.default};
`;

function Drawer({
  children,
  width,
  backUrl,
  title,
  subtitle,
  buttons,
  error,
  header,
}) {
  const [show, setShow] = useState(true);
  const history = useHistory();
  let timeout = null;

  useEffect(() => {
    return () => (timeout = null);
  }, []);

  const handleBack = () => {
    setShow(false);
    timeout = setTimeout(() => {
      history.push(backUrl);
    }, 250);
  };

  return (
    <Page>
      <CSSTransition in={show} appear timeout={250} classNames="drawer_bg">
        <Background className="drawer_bg" onClick={handleBack} />
      </CSSTransition>
      <CSSTransition in={show} appear timeout={250} classNames="drawer_content">
        <Main width={width} className="drawer_content">
          <Section>
            {error && <div>Not Found</div>}
            {!error && (
              <>
                {!header && <Header>
                  {(title || subtitle) && (
                    <Content>
                      {title && (
                        <Typography weight="bold" size="20" height="28">
                          {title}
                        </Typography>
                      )}
                      {subtitle && (
                        <Typography color={TEXT_COLORS.gray} size="15" height="20">
                          {subtitle}
                        </Typography>
                      )}
                    </Content>
                  )}
                  {
                    <Buttons>
                      {buttons}
                      <Button onClick={handleBack} iconLeft="close" round />
                    </Buttons>
                  }
                </Header>}
                {header && header}
                <Body>{children}</Body>
              </>
            )}
          </Section>
        </Main>
      </CSSTransition>
    </Page>
  );
}

Drawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  width: PropTypes.number,
  backUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  error: PropTypes.bool,
}

Drawer.defaultProps = {
  children: undefined,
  width: 800,
  backUrl: "",
  title: "",
  subtitle: "",
  buttons: undefined,
  error: false,
}

export default Drawer;
