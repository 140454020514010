import React from 'react';
import styled from 'styled-components';
import { FONTS, TEXT_COLORS } from '../../const/';

const StyledH3 = styled.h3`
  font: ${FONTS.l}
  color: ${TEXT_COLORS.default};
  font-weight: 800;
  margin: 0;
  padding: 8px 0;
`;

function H3(props) {
  const {
    title,
    ...other
  } = props;
  return (
    <StyledH3 {...other}>
      {title}
    </StyledH3>
  )
}

H3.defaultProps = {
  title: '',
};

export default H3;
