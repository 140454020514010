import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { Theme } from 'storybook-addon-styled-component-theme/dist/types/Theme';


export type BoxProps = {
  pass: boolean,
  m: number,
  mx: number,
  my: number,
  ml: number,
  mr: number,
  mt: number,
  mb: number,
  p: number,
  px: number,
  py: number,
  pl: number,
  pr: number,
  pt: number,
  pb: number,
};


const BoxWrapper = (Component: ReactNode) => styled<Partial<BoxProps> & Theme>(Component)`

  ${props => props.m && `margin: ${props.theme.spacing * props.m}px;`}
  ${props => props.p && `padding: ${props.theme.spacing * props.p}px;`}

  ${props => (props.mx || props.my) &&
    `margin: ${props.my ? props.theme.spacing * props.my : 0}px
   ${props.mx ? props.theme.spacing * props.mx : 0}px;`
  }

  ${props => props.ml && `margin-left: ${props.theme.spacing * props.ml}px;`}
  ${props => props.mr && `margin-right: ${props.theme.spacing * props.mr}px;`}
  ${props => props.mt && `margin-top: ${props.theme.spacing * props.mt}px;`}
  ${props => props.mb && `margin-bottom: ${props.theme.spacing * props.mb}px;`}

  ${props => (props.px || props.py) &&
    `padding: ${props.py ? props.theme.spacing * props.py : 0}px 
    ${props.px ? props.theme.spacing * props.px : 0}px;`
  }

  ${props => props.pl && `padding-left: ${props.theme.spacing * props.pl}px;`}
  ${props => props.pr && `padding-right: ${props.theme.spacing * props.pr}px;`}
  ${props => props.pt && `padding-top: ${props.theme.spacing * props.pt}px;`}
  ${props => props.pb && `padding-bottom: ${props.theme.spacing * props.pb}px;`}
`;


const Container = BoxWrapper(({ className, children }: HTMLAttributes<Element>) =>
  React.cloneElement(children as React.ReactElement, { className })
);


const StyledBox = styled.div<BoxProps & Theme>`
  ${props => props.m && `margin: ${props.theme.spacing * props.m}px;`}
  ${props => props.p && `padding: ${props.theme.spacing * props.p}px;`}

  ${props => (props.mx || props.my) &&
    `margin: ${props.my ? props.theme.spacing * props.my : 0}px 
    ${props.mx ? props.theme.spacing * props.mx : 0}px;`
  }

  ${props => props.ml && `margin-left: ${props.theme.spacing * props.ml}px;`}
  ${props => props.mr && `margin-right: ${props.theme.spacing * props.mr}px;`}
  ${props => props.mt && `margin-top: ${props.theme.spacing * props.mt}px;`}
  ${props => props.mb && `margin-bottom: ${props.theme.spacing * props.mb}px;`}

  ${props => (props.px || props.py) &&
    `padding: ${props.py ? props.theme.spacing * props.py : 0}px 
    ${props.px ? props.theme.spacing * props.px : 0}px;`
  }

  ${props => props.pl && `padding-left: ${props.theme.spacing * props.pl}px;`}
  ${props => props.pr && `padding-right: ${props.theme.spacing * props.pr}px;`}
  ${props => props.pt && `padding-top: ${props.theme.spacing * props.pt}px;`}
  ${props => props.pb && `padding-bottom: ${props.theme.spacing * props.pb}px;`}
`;


const Box = React.forwardRef<HTMLDivElement, Partial<BoxProps> & HTMLAttributes<Element>>(({
  m, mx, my, ml, mr, mt, mb,
  p, px, py, pl, pr, pt, pb,
  pass, children, ...rest }, ref) => {


  if (pass) return (
    <Container
      children={children}
      m={m} mx={mx} my={my} ml={ml} mr={mr} mt={mt} mb={mb}
      p={p} px={px} py={py} pl={pl} pr={pr} pt={pt} pb={pb}
      {...rest}
    />
  );
  else return (
    <StyledBox
      ref={ref}
      m={m} mx={mx} my={my} ml={ml} mr={mr} mt={mt} mb={mb}
      p={p} px={px} py={py} pl={pl} pr={pr} pt={pt} pb={pb}
      {...rest}
    >
      {children}
    </StyledBox>
  );
});


export default Box;
