import React, { useRef, useContext, useEffect, useState, memo } from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { debounce } from "../../assistants/";
import { BrickContext } from './BrickContext';

const elementResizeDetectorMaker = require("element-resize-detector");

const Brick = styled.div`
  width: 100%;
`;

const BrickWrap = styled.div`
  width: 100%;
`;

function BricksItem({ children }) {
  const gridItemWrapper = useRef();
  const gridContext = useContext(BrickContext);
  const [resized, setResized] = useState(false);
  let resizeTimer;

  useEffect(() => {
    let erd = elementResizeDetectorMaker();
    erd.listenTo(gridItemWrapper.current.children[0], debounce(function (element) {
      clearTimeout(resizeTimer);
      setResized(true);
      resizeTimer = setTimeout(function () {
        setResized(false);
      }, gridContext.timer);
    }, gridContext.timer));
  }, []);

  const itemLoaded = () => {
    if (!resized) {
      const refHeight = gridItemWrapper.current.children[0].clientHeight;
      const refWidth = gridItemWrapper.current.children[0].clientWidth;
      const { rowHeight, gap: rowGap } = gridContext;

      const desiredHeight = (refWidth * refHeight) / refWidth;
      const rowSpan = Math.ceil((desiredHeight + rowGap) / (rowHeight + rowGap));
      gridItemWrapper.current.style.gridRowEnd = `span ${rowSpan}`;
      gridContext.setLoaded((prev) => true)
    }
  };

  useEffect(itemLoaded, [resized]);

  return (
    <BrickWrap ref={gridItemWrapper} >
      <Brick>
        {children}
      </Brick>
    </BrickWrap>
  );
};

BricksItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
}

BricksItem.defaultProps = {
  children: undefined,
}

export default memo(BricksItem);
