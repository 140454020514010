import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

const StyledContainer = styled.div`
  padding: 0 16px;
  margin: 0 auto;
`;

function Container(props) {
  const {
    children,
    ...other
  } = props;
  return (
    <StyledContainer {...other}>{children}</StyledContainer>
  );
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
};

Container.defaultProps = {
  children: undefined,
};

export default Container;
