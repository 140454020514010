import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import Icon from '../Icon/';
import { LINE_SIZE, FONTS } from '../../const/';
import { colorToHsla } from '../../assistants/';

const getFocusColorHelper = color => {
  const [h,s,l,a] = colorToHsla(color);
  return `hsla(${h},${s}%,${l}%,${.32})`
}

const getCheckedColorHelper = color => {
  const [h,s,l,a] = colorToHsla(color);
  return `hsla(${h},${s}%,${l}%,${.24})`
}

const getCheckboxColorHelper = color => {
  const [h,s,l,a] = colorToHsla(color);
  return `hsla(${h},${s}%,${l}%,${.08})`
}

const getBordersColorHelper = color => {
  const [h,s,l,a] = colorToHsla(color);
  return `hsla(${h},${s}%,${l}%,${.04})`
}

const I = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: 16px;
  height: 16px;
  opacity: ${props => props.checked ? 1 : 0};
`;

const I2 = styled.div`
  position: absolute;
  top: -7px;
  left: -2px;
  width: 24px;
  height: 24px;
  opacity: ${props => props.checked ? 1 : 0};
`;

const FakeInput = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  border-radius: ${props => props.fakeType === "radio" ? "50%" : "4px"};
  background: ${props => props.checked ? getCheckedColorHelper(props.theme.background.brand) : getCheckboxColorHelper(props.theme.background.contrast)};
  border: 1px solid ${props => props.disabled ? "transparent" : getBordersColorHelper(props.theme.background.contrast)};
  vertical-align: middle;
  margin: ${props => Math.round((LINE_SIZE[props.size] - 16) / 2)}px 0;
  box-sizing: border-box;

  ${props => props.fakeType === "radio" && props.checked && (`
    &:after {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      top: 4px;
      left: 4px;
      background: ${props.disabled ? props.theme.icon.color.default : props.theme.icon.color.primary};
    }
  `)}
`;

const Input = styled.input`
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
`;

const TitleContainer = styled.div`
  padding: 0 8px;
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font: ${props => props.theme.font[props.size]};
  color: ${props => props.disabled ? props.theme.text.secondary : props.theme.text.default};
  margin: 0;
  padding: 0;
`;

const Subtitle = styled.div`
  font: ${FONTS.xs};
  overflow: hidden;
  color: ${props => props.disabled ? props.theme.text.tertiary : props.theme.text.secondary};
  text-overflow: ellipsis;
  margin: 0;
  padding-top: 2px;
`;

const Label = styled.label`
  display: inline-flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  padding: 2px 0;

  &:hover {
    cursor: pointer;
  }

  &:hover ${ Title } {
    color: ${props => props.theme.link.hover};
  }

  &:active ${ Title } {
    color: ${props => props.theme.link.active};
  }

  &:focus {
    outline: none;
  }

  &:focus ${ FakeInput } {
    box-shadow: 0 0 0 2px ${props => getFocusColorHelper(props.theme.button.primary)};
    border-color: ${props => props.theme.button.primary};
    background:  ${props => getCheckedColorHelper(props.theme.button.primary)};
  }

  &[disabled] {
    pointer-events: none;
  }
`;

function RadioOrCheckbox(props) {
  const {
    name,
    title,
    subtitle,
    checked,
    onClick,
    type,
    disabled,
    icon,
    size,
    ...other
  } = props;
  return (
    <Label disabled={disabled} {...other}>
      <FakeInput checked={checked} disabled={disabled} fakeType={type} size={size}>
        <Input type={type} name={name} disabled={disabled} checked={checked === undefined ? false : checked} onChange={onClick} />
        {type === "checkbox" && (
          icon ? <I checked={checked}><Icon icon={icon} size="xs" color={disabled ? "default" : "primary"} /></I> :
            <I2 checked={checked}><Icon icon="mark" size="m" color={disabled ? "default" : "primary"} /></I2>
        )}
      </FakeInput>
      {title && (
        <TitleContainer>
          <Title disabled={disabled} size={size}>{title}</Title>
          {subtitle && (
            <Subtitle disabled={disabled}>{subtitle}</Subtitle>
          )}
        </TitleContainer>
      )}
    </Label>
  )
}

RadioOrCheckbox.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  size: PropTypes.string,
}

RadioOrCheckbox.defaultProps = {
  name: "",
  type: "radio",
  disabled: false,
  title: "",
  subtitle: "",
  checked: false,
  onClick: undefined,
  icon: "",
  size: "s",
};

export default RadioOrCheckbox;
