export default function validation(value, rules, objValDependencies) {
  const result = {isValid: true};
  rules.some(rule => {
    if (Array.isArray(rule.validator)) {
      const { textResult, isValid } = groupValidation(rule);
      result.isValid = isValid;
      result.message = textResult;
      return !isValid;
    } else {
      const resultValid = rule.validator(value, objValDependencies);
      result.isValid = resultValid;
      result.message = rule.message;
      return !resultValid;
    }
  });
  return result;
}

function groupValidation(rule) {
  let textResult = rule.message;
  let isValid = true;
  rule.validator.forEach((el, i) => {
    if (!el.validator()) {
      isValid = false;
      textResult += `${el.message}${i === rule.validator.length - 1 ? "" : ","} `;
    }
  });

  return { textResult, isValid };
}