import { css, CSSObject } from "styled-components";
import { horizontal, IAnchor, ITransform, vertical } from "./types";

export const calcAnchorPosition = ({ vertical, horizontal }: IAnchor) => {
  const styles: { [key in vertical | horizontal]?: string; } = {};

  styles[vertical === 'center' ? 'top' : vertical] = getAnchorStylesByPosition(vertical);
  styles[horizontal === 'center' ? 'left' : horizontal] = getAnchorStylesByPosition(horizontal);

  return css(styles).join(' ').trim();
}

function getAnchorStylesByPosition(position: vertical | horizontal) {
  const anchorWidth = 10;
  const halfAnchorWidth = anchorWidth * 0.5;

  switch (position) {
    case 'center':
      return `calc(50% - ${halfAnchorWidth}px)`
    default:
      return `-${halfAnchorWidth}px`
  }
}

export const getContentPosition = ({ anchorDimensions, transform, spacing = 0 }:
  { anchorDimensions: { left: number; top: number, width: number }, transform: ITransform, spacing?: number }) => {
  const styles: CSSObject = {};
  const offest = anchorDimensions.width * 0.5;

  styles.top = anchorDimensions.top + offest;
  styles.left = anchorDimensions.left + offest;

  if (transform.vertical === 'bottom') styles.top -= spacing;
  else styles.top += spacing

  const translateY = getTranslateByPositionType(transform.vertical);
  const translateX = getTranslateByPositionType(transform.horizontal);

  styles.transform = `translate(${translateX}%, ${translateY}%)`;

  return styles;
}

function getTranslateByPositionType(positionType: vertical | horizontal) {
  switch (positionType) {
    case 'top':
    case 'left':
      return 0;
    case 'center':
      return -50;
    default:
      return -100;
  }
}