import { TEXT_SIZE, LINE_SIZE } from "./sizes";

export const FONT_FAMILY = {
  default: `"IBM Plex Sans", "Helvetica Neue", Helvetica, Tahoma, Arial, sans-serif`,
}

export const FONTS = {
  xxs: `${TEXT_SIZE.xxs}px/${LINE_SIZE.xxs}px ${FONT_FAMILY.default}`,  // 11/12
  xs:  `${TEXT_SIZE.xs}px/${LINE_SIZE.xs}px ${FONT_FAMILY.default}`,    // 13/15
  s:   `${TEXT_SIZE.s}px/${LINE_SIZE.s}px ${FONT_FAMILY.default}`,      // 15/20
  m:   `${TEXT_SIZE.m}px/${LINE_SIZE.m}px ${FONT_FAMILY.default}`,      // 17/24
  l:   `${TEXT_SIZE.l}px/${LINE_SIZE.l}px ${FONT_FAMILY.default}`,      // 20/28
  xl:  `${TEXT_SIZE.xl}px/${LINE_SIZE.xl}px ${FONT_FAMILY.default}`,    // 24//32
  xxl: `${TEXT_SIZE.xxl}px/${LINE_SIZE.xxl}px ${FONT_FAMILY.default}`,  // 32/40
}
