import React, { Children, useRef, useState, useEffect, useCallback, Component } from 'react';
import PropTypes, { element } from "prop-types";
import styled from 'styled-components';
import { calculateStyle } from './helpers/';
import { getSide } from './helpers/calculateStyle';
import { isLight, colorToHsla } from '../../assistants/';

import Portal from '../../blocks/Portal';

const TooltipView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.width}px;
  color: ${props => props.isLight ? props.theme.text.default : props.theme.text.contrast};
  padding: 12px 16px;
  border-radius: 2px;
  background: ${props => props.theme.background[props.tooltipColor] ? props.theme.background[props.tooltipColor] : props.tooltipColor};
  font: ${props => props.theme.font.xs};
  box-shadow: 0 8px 32px 0
    ${props => props.isLight ? "hsla(0,0%,0%,.32)" : `hsla(${props.hsla.h},${props.hsla.s}%,${props.hsla.l}%,.32)`};
  text-overflow: ellipsis;
  z-index: 99999999;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0s ease 0.1s;

  ${props => props.show && `
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s ease 0.05s, visibility 0s ease 0s;
  `}

  &::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 5px;

    ${props => (props.position === "top-center" || props.position === "top-start" || props.position === "top-end") && (`
      top: 100%;
      border-color: ${props.theme.background[props.tooltipColor] ? props.theme.background[props.tooltipColor] : props.tooltipColor} ${props.theme.background[props.tooltipColor] ? props.theme.background[props.tooltipColor] : props.tooltipColor} transparent transparent;
      left: ${props.afterLeft}px;
    `)}

    ${props => (props.position === "bottom-center" || props.position === "bottom-start" || props.position === "bottom-end") && (`
      bottom: 100%;
      border-color: transparent ${props.theme.background[props.tooltipColor] ? props.theme.background[props.tooltipColor] : props.tooltipColor} ${props.theme.background[props.tooltipColor] ? props.theme.background[props.tooltipColor] : props.tooltipColor} transparent;
      left: ${props.afterLeft}px;
    `)}

    ${props => (props.position === "left-center" || props.position === "left-start" || props.position === "left-end") && (`
      left: 100%;
      border-color: ${props.theme.background[props.tooltipColor] ? props.theme.background[props.tooltipColor] : props.tooltipColor} transparent transparent ${props.theme.background[props.tooltipColor] ? props.theme.background[props.tooltipColor] : props.tooltipColor};
      top: ${props.afterTop}px;
    `)}

    ${props => (props.position === "right-center" || props.position === "right-start" || props.position === "right-end") && (`
      right: 100%;
      border-color: ${props.theme.background[props.tooltipColor] ? props.theme.background[props.tooltipColor] : props.tooltipColor} ${props.theme.background[props.tooltipColor] ? props.theme.background[props.tooltipColor] : props.tooltipColor} transparent transparent ;
      top: ${props.afterTop}px;
    `)}
  }
`;

const disapearDelay = 200;

function Tooltip(props) {
  const {
    width,
    children,
    text,
    component,
    side,
    space,
    display,
    afterSize,
    afterVMarginFromTooltipBorder,
    afterHMarginFromTooltipBorder,
    tooltipViewportMargin,
    afterVMarginFromChildrenBorder,
    afterHMarginFromChildrenBorder,
    tooltipColor,
    ...other
  } = props;
  const [pointedElem, setPointedElem] = useState(null);
  const [hover, setHover] = useState(false);
  const [currentSide, setSide] = useState(side);
  const [styles, setStyles] = useState({});
  const [afterLeft, setAfterLeft] = useState();
  const [afterTop, setAfterTop] = useState();
  const elTooltip = useRef(null);
  let timer;

  useEffect(() => {
    if (pointedElem && elTooltip.current) {
      let currentSide = getSide(pointedElem, elTooltip.current, side, afterSize, space, tooltipViewportMargin);
      const [styles, afterLeftCalc, afterTopCalc] = calculateStyle(pointedElem, elTooltip.current, currentSide, afterSize, space, afterVMarginFromTooltipBorder, afterHMarginFromTooltipBorder, tooltipViewportMargin, afterVMarginFromChildrenBorder, afterHMarginFromChildrenBorder);
      setAfterLeft(afterLeftCalc);
      setAfterTop(afterTopCalc);
      setStyles(styles);
      setSide(currentSide);
    }
  }, [pointedElem, elTooltip.current, hover]);

  const enterPlaces = useCallback((e) => {
    clearTimeout(timer);
    setHover(true);
    setPointedElem(e.currentTarget);
  }, []);

  const enterTooltip = useCallback(() => {
    setHover(true);
    clearTimeout(timer);
  }, []);

  const leavePlaces = useCallback(() => {
    timer = setTimeout(() => {
      setHover(false);
    }, disapearDelay);
  }, []);

  const childElement = Children.only(children);
  const [h, s, l, a] = colorToHsla(tooltipColor);
  return (
    <>
      {display && (
        <Portal isOpen={true}>
          <TooltipView
            onMouseEnter={enterTooltip}
            onMouseLeave={leavePlaces}
            role="tooltip"
            position={currentSide}
            ref={elTooltip}
            style={styles}
            afterLeft={afterLeft}
            afterTop={afterTop}
            afterSize={afterSize}
            width={width}
            isLight={isLight(tooltipColor)}
            hsla={{ h: h, s: s, l: l, a: a }}
            show={hover}
            tooltipColor={tooltipColor}
            {...other}
          >
            {text && text}
            {component && component}
          </TooltipView>
        </Portal>
      )}
      {React.cloneElement(childElement, {
        onMouseEnter: enterPlaces,
        onMouseLeave: leavePlaces,
      })}
    </>
  )
}


Tooltip.defaultProps = {
  text: "",
  // component: ,
  side: "top-center",
  space: 0,
  display: true,
  afterSize: 14,
  afterVMarginFromTooltipBorder: 8,
  afterHMarginFromTooltipBorder: 4,
  tooltipViewportMargin: 4,
  afterVMarginFromChildrenBorder: 4,
  afterHMarginFromChildrenBorder: 4,
  width: '200',
  tooltipColor: 'default',
}

export default Tooltip;
