import styled from 'styled-components';
import { IDetails, position } from './types';

export const AccordionWrapper = styled.div`
  position: relative;
`;

export const Details = styled.section<IDetails>`
  width: 100%;
  min-height: 0px;
  height: 0;
  padding-bottom: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0.2;
  transform: scaleY(0.95) translateY(-5%);
  transition: all 0.1s ease;

  ${props => props.divider && "padding-top: 4px;"}

  ${props => props.isOpen && `
    visibility: visible;
    overflow: visible;
    height: 100%;
    opacity: 1;
    
    transform: scaleY(1) translateY(0);
  `}
`;

export const Summary = styled.header`
  display: flex;
  align-items: center;
  /* padding: 0px ${props => props.theme.spacing}px; */
  cursor: pointer;
`;

export const Title = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${props => props.theme.spacing}px;
`;

export const Buttons = styled.div`
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;

  & > * {
    margin-right: 4px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
`;

export const IconMore = styled.div<{ isOpen?: boolean; position?: position }>`
  width: 20px;
  height: 20px;
  ${props => props.isOpen && `
    transform: rotate(90deg);
  `}

  ${props => props.position === "right" && `
    transform: rotate(180deg);
  `}
`;
