import { MAIN } from '../../themes';

export enum DIVIDER_ORIENTATIONS {
  X = "X",
  Y = "Y"
}

export interface IDivider {
  direction?: keyof typeof DIVIDER_ORIENTATIONS;
  size?: number;
  zIndex?: number;
  color?: keyof typeof MAIN.background;
  className?: string;
}