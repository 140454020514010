import { colorToHsla } from '../';

const isLight = (color) => {
  const [h,s,l,a] = colorToHsla(color);
  if (l >= 80 || a >= 44) { return true };
  if ( l <= 44 || h <= 20 || h >= 216 || s <= 112 - l || h <= 100 - l || h >= 180 + (0.8 * l - 38.4)) { return false };
  return true;
};

export default isLight;
