import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

const SIZE = {
  1: `
    width: 100%;
    max-width: 100%;
  `,
  2: `
    flex: 0 0 16.6666%;
    max-width: 16.6666%;
  `,
  3: `
    flex: 0 0 25%;
    max-width: 25%;
  `,
  4: `
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  `,
  5: `
    flex: 0 0 41.6666%;
    max-width: 41.6666%;
  `,
  6: `
    flex: 0 0 50%;
    max-width: 50%;
  `,
  7: `
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  `,
  8: `
    flex: 0 0 66.6666%;
    max-width: 66.6666%;
  `,
  9: `
    flex: 0 0 75%;
    max-width: 75%;
  `,
  10: `
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  `,
  11: `
    flex: 0 0 91.6666%;
    max-width: 91.6666%;
  `,
  12: `
    flex: 0 0 100%;
    max-width: 100%;
  `
}

const StyledCol = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0 8px;
  flex-basis: 0px;
  flex-grow: 1;
  box-sizing: border-box;
  ${props => props.order && (`
    order: ${props.order};
  `)}

  ${props => props.xl && SIZE[props.xl]}

  @media (max-width: 1440px) {
    ${props => props.l && SIZE[props.l]}
  }

  @media (max-width: 1200px) {
    ${props => props.m && SIZE[props.m]}
  }

  @media (max-width: 1024px) {
    ${props => props.s && SIZE[props.s]}
  }

  @media (max-width: 720px) {
    ${props => props.xs && SIZE[props.xs]}
  }

  @media (max-width: 576px) {
    ${props => props.xxs && SIZE[props.xxs]}
  }
`;


function Col(props) {
  const {
    xl,
    l,
    m,
    s,
    xs,
    xxs,
    order,
    children,
    ...other
  } = props;
  return (
    <StyledCol xl={xl} l={l} m={m} s={s} xs={xs} xxs={xxs} order={order} {...other}>{children}</StyledCol>
  );
}

Col.propTypes = {
  xl: PropTypes.number,
  l: PropTypes.number,
  m: PropTypes.number,
  s: PropTypes.number,
  xs: PropTypes.number,
  xxs: PropTypes.number,
  order: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
};

Col.defaultProps = {
  xl: 1,
  l: 1,
  m: 1,
  s: 1,
  xs: 1,
  xxs: 1,
  order: undefined,
  children: undefined,
};

export default Col;
