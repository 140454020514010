import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';


const PointedTime = styled.span`
  position: absolute;
  top: -37px;  
  left: ${({ left }) => left}px;
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.light};
  padding: 5px 8px 4px;
  background: hsla(0,0%,0%,.32);
  border-radius: 4px;
`;


const TrackbarPointValueLabel = ({ left, value }) => {
  const ref = useRef(null);
  const [leftToCenter, setLeftToCenter] = useState(0);
  const [paddingsX, setPaddingsX] = useState(0);


  useEffect(
    () => {
      if (!ref.current) return;
      const { paddingLeft, paddingRight } = getComputedStyle(ref.current);
      const sum = parseFloat(paddingLeft) + parseFloat(paddingRight);
      setPaddingsX(sum);
    },
    [ref.current]
  )


  useEffect(
    () => {
      if (!ref.current) return;
      setLeftToCenter(ref.current.clientWidth - paddingsX + left);
    },
    [value]
  );


  return (
    <PointedTime
      ref={ref}
      left={leftToCenter}
    >{value}</PointedTime>
  )
}


export default TrackbarPointValueLabel;