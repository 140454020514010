import { RefObject, useCallback, useEffect } from "react";

const useOutsideClick = ({ ref, onClose }: { ref: RefObject<any> | RefObject<any>[], onClose: () => void }) => {
  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (Array.isArray(ref)) {
        if (ref.every(refEl => !refEl.current.contains(e.target)))
          onClose();
        return;
      }

      if (!ref.current.contains(e.target)) onClose();
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('click', clickListener)
    return () => document.removeEventListener('click', clickListener)
  }, [])
};

export default useOutsideClick;