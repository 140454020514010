import React, {useLayoutEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Portal } from "../../blocks/";
import { Button } from "../../elements/";
import { ModalContent, ModalHeader, ModalBody } from "./";

const StyledModal = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 9999999;
  justify-content: center;
  align-items: center;
  padding: 8px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
`;

const Background = styled.div`
  position: absolute;
  ${props => props.onClick ? `cursor: pointer;` : ''}
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(0,0%,0%,.72);
  z-index: 1;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
`;

function Modal(props) {
  const {
    isOpen,
    children,
    logo,
    icon,
    iconColor,
    fixed,
    close,
    header,
    title,
    subtitle,
    error,
    modalColor,
    ...other
  } = props;

  const onKeyDown = e => {
    e.keyCode === 27 && close && close();
  }

  useLayoutEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);

  return (
    <Portal isOpen={isOpen}>
      <StyledModal {...other}>
        <Background onClick={close} />
        <ModalContent fixed={fixed} modalColor={modalColor}>
          {close && <CloseButton forwardedAs="div" iconLeft="close" size="m" variant="clear" color='default' round onClick={close} />}
          {(header || logo || icon) && <ModalHeader logo={logo} icon={icon} iconColor={iconColor}>{header}</ModalHeader>}
          {(children || title || subtitle) && (
            <ModalBody title={title} subtitle={subtitle} error={error}>{children}</ModalBody>
          )}
        </ModalContent>
      </StyledModal>
    </Portal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  fixed: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  logo: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  close: PropTypes.func,
  error: PropTypes.string,
  modalColor: PropTypes.string,
}

Modal.defaultProps = {
  isOpen: false,
  children: undefined,
  fixed: undefined,
  title: "",
  modalColor: 'default',
  subtitle: "",
  header: undefined,
  logo: "",
  icon: "",
  iconColor: "",
  close: undefined,
  children: undefined,
  error: "",
};

export default Modal;
