import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

import Icon from "../Icon/";

const StyledWithIcon = styled.div`
  max-width: 100%;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;

  & > svg {
    vertical-align: text-top;
    margin: ${props => props.position === "left" ? "0 2px 0 0" : "0 0 0 2px"};
  }
`;

function WithIcon(props) {
  const {
    children,
    position,
    icon,
    size,
    color,
    ...other
  } = props;
  return (
    <StyledWithIcon position={position} {...other}>
      {icon && position === 'left' && <Icon icon={icon} color={color} size={size} />}
      {children}
      {icon && position === 'right' && <Icon icon={icon} color={color} size={size} />}
    </StyledWithIcon>
  );
}

WithIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  position: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
}

WithIcon.defaultProps = {
  children: '',
  position: "right",
  size: "xs",
  icon: "",
  color: 'default',
};

export default WithIcon;
