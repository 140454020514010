import { ReactNode } from "react";

export enum GRID_DIRECTIONS_TYPES {
  'row' = "row",
  'row-reverse' = 'row-reverse',
  'column' = 'column',
  'column-reverse' = 'column-reverse'
}

export enum GRID_JUSTIFY_TYPES {
  'flex-start' = "flex-start",
  'center' = 'center',
  'flex-end' = 'flex-end',
  'space-between' = 'space-between',
  'space-around' = 'space-around',
  'space-evenly' = 'space-evenly',
}

export enum GRID_ALIGN_ITEMS_TYPES {
  'flex-start' = "flex-start",
  'center' = 'center',
  'flex-end' = 'flex-end',
  'stretch' = 'stretch',
  'baseline' = 'baseline',
}

export const GRID_MEDIA_SIZES = ['auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;

export interface IGridView extends IGrid {
  itemSpacing?: number;
}

export interface IGrid extends IContainer {
  item?: boolean;
  container?: boolean;
  xs?: typeof GRID_MEDIA_SIZES[number];
  sm?: typeof GRID_MEDIA_SIZES[number];
  md?: typeof GRID_MEDIA_SIZES[number];
  lg?: typeof GRID_MEDIA_SIZES[number];
  xl?: typeof GRID_MEDIA_SIZES[number];
}

export interface IContainer {
  children: ReactNode;
  spacing?: number;
  wrap?: boolean;
  direction?: keyof typeof GRID_DIRECTIONS_TYPES;
  justify?: keyof typeof GRID_JUSTIFY_TYPES;
  alignItems?: keyof typeof GRID_ALIGN_ITEMS_TYPES;
  className?: string;
}