import React from 'react';
import PropTypes from "prop-types";
import styled, { keyframes } from 'styled-components';
import { INDENT_SIZE } from '../../const/';

const wave = size => keyframes`
  from, to {
    transform: translateY(0);
  }

  25% {
    transform: translateY(${INDENT_SIZE[size] + 'px'});
  }

  75% {
    transform: translateY(-${INDENT_SIZE[size] + 'px'});
  }
`;

const StyledPreloader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  padding: ${props => props.padding};
  flex-shrink: 0;
  box-sizing: content-box;
  justify-content: center;
  flex-grow: 1;
`;

const Dot = styled.div`
  width: ${props => INDENT_SIZE[props.size] + 1 + 'px'};
  height: ${props => INDENT_SIZE[props.size] + 1 + 'px'};
  margin: ${props => INDENT_SIZE[props.size] + 'px'} ${props => INDENT_SIZE[props.size]/2 + 'px'};
  animation: ${props => wave(props.size)} .88s linear infinite;
  animation-delay: ${props => props.delay};
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  background: ${props => props.theme.button[props.dotColor] ? props.theme.button[props.dotColor] : props.dotColor};
`;

function Preloader(props) {
  const {
    size,
    color,
    padding,
    ...other
  } = props;
  return (
    <StyledPreloader size={size} padding={padding} {...other}>
      <Dot size={size} dotColor={color} delay="0" />
      <Dot size={size} dotColor={color} delay=".150s" />
      <Dot size={size} dotColor={color} delay=".300s" />
    </StyledPreloader>
  )
}

Preloader.propTypes = {
  size: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string
}

Preloader.defaultProps = {
  size: "s",
  padding: "0 4px",
  color: "primary",
};

export default Preloader;
