import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../elements/";
import { FONTS, COLORS, TEXT_SIZE, LINE_SIZE } from "../../const/";

const NotificationStyled = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  padding: 12px 16px;
  background: ${(props) => (props.color ? props.color : "rgba(15, 23, 36, 0.96)")};
  border: 1px solid #363f4f;
  width: ${(props) => (props.width ? props.width : "320")}px;
  border-left: 0;
  border-right: 0;
  box-sizing: border-box;

  &:nth-last-child(1) {
    border-bottom: 0;
    border-radius: 0 0 4px 4px;
  }

  &:nth-child(1) {
    border-top: 0;
    border-radius: 4px 4px 0 0;
  }

  ${props => props.isOnly && (`
    &:nth-child(1) {
      border-top: 0;
      border-radius: 4px;
    }
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  width: 100%;
  min-height: 32px;
  align-items: center;
  word-break: break-word;
`;

const Text = styled.p`
  margin: 0;
  padding: 6px 0;
  color: ${COLORS.white};
  font: ${FONTS.s};
`;

const ButtonClose = styled.div`
  float: right;
  padding-left: 6px;
`;

function Notification({
  close,
  component,
  isOnly,
  title,
  message,
  timeOut,
  onRequestHide,
  callback,
  children,
  ...other
}) {
  let timer;
  let showBtnClose = timeOut != 0 || close;

  const requestHide = () => {
    onRequestHide();
    callback && callback();
  };

  useEffect(() => {
    if (timeOut !== 0) {
      timer = setTimeout(() => {
        requestHide();
      }, timeOut);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <NotificationStyled isOnly={isOnly} {...other}>
      <Content showBtnClose={showBtnClose}>
        {showBtnClose && (
          <ButtonClose>
            <Button iconLeft="close" variant="clear" round color="light" onClick={requestHide} />
          </ButtonClose>
        )}
        {children}
        {title && <Text>{title}</Text>}
        {message && <Text>{message}</Text>}
        {component && component}
      </Content>
    </NotificationStyled>
  );
}

Notification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  timeOut: PropTypes.number,
  onRequestHide: PropTypes.func,
  callback: PropTypes.func,
  close: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  isOnly: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ])
}

Notification.defaultProps = {
  title: "",
  message: "",
  timeOut: 5000,
  onRequestHide: undefined,
  callback: undefined,
  close: false,
  component: undefined,
  isOnly: false,
  children: undefined,
};

export default Notification;
