import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useMenuState } from './menuContext';

const StyledMenuSection = styled.div`
  padding: 8px 0;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme, light }) => light ? 'hsla(0,0%,0%,0.08)' : 'hsla(0,0%,100%,0.08)'};
`;

const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme, light }) => light ? theme.text.dark : theme.text.light};
  padding: 0 20px 9px;
  font-weight: 500;
`;

function MenuSection(props) {
  const {
    children,
    title,
    ...other
  } = props;

  const { light, small } = useMenuState();

  return (
    <StyledMenuSection light={light} {...other}>
      {(title && !small) && <Title light={light}>{title}</Title>}
      {children}
    </StyledMenuSection>
  );
}

MenuSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]),
  title: PropTypes.string,
}

MenuSection.defaultProps = {
  children: undefined,
  title: "",
};

export default MenuSection;
