import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon } from "../../elements/";

const StyledModalIcon = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid ${props => props.color};
`;

function ModalIcon(props) {
  const {
    icon,
    color,
    ...other
  } = props;
  return (
    <StyledModalIcon color={color} {...other}>
      <Icon icon={icon} size="modal" color={color} />
    </StyledModalIcon>
  );
}

ModalIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
}

ModalIcon.defaultProps = {
  icon: "",
  color: "",
}

export default ModalIcon;
