import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BACKGROUND_COLORS } from "../../const/";

const StyledModalContent = styled.div`
  margin: 80px 0;
  padding: 20px 40px;
  background: ${({ theme, modalColor}) => theme.background[modalColor] ? theme.background[modalColor] : modalColor};
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  max-width: ${props => props.fixed ? props.fixed + "px" : "100%"};
  z-index: 2;
  transform: translateY(-2vh);
  overflow: hidden;

  @media (max-width: 720px) {
    padding: 20px 24px;
  }
`;

function ModalContent(props) {
  const {
    children,
    fixed,
    modalColor,
    ...other
  } = props;
  return (
    <StyledModalContent fixed={fixed} modalColor={modalColor} {...other}>
      {children}
    </StyledModalContent>
  )
}

ModalContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  fixed: PropTypes.number,
  modalColor: PropTypes.string,
}

ModalContent.defaultProps = {
  children: undefined,
  fixed: undefined,
  modalColor: 'default',
};

export default ModalContent;
