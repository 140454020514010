import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Portal from '../Portal';

let bodyDiv = document.querySelectorAll('body > div');
const portalRoot = bodyDiv[bodyDiv.length - 1];
const arrowWidth = 10;

const DropdownWrap = styled.div`
  display: inline-block;
  width: max-content;
  position: relative;
`;

const ContentWrap = styled.div`
  position: absolute;
  min-width: 200px;
  max-width: 264px;
  margin-top: ${props => (props.spacesTop ? props.spacesTop : 0)}px;
  width: auto;
  background: #fff;
  border-radius: 4px;
  z-index: 100;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.24);

  ${props =>
    props.arrow &&
    `&::before {
      content: " ";
      position: absolute;
      border-width: ${arrowWidth}px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      transform: translate(-50%, -100%);
      z-index: 1002;

      ${props.position === 'left' ? (`left: calc(${arrowWidth}px - ${props.arrowSpace}px);`) : ''}
      ${props.position === 'center' ? (`left: 50%;`) : ''}
      ${props.position === 'right' ? (`left: calc(100% - ${arrowWidth}px - ${props.arrowSpace}px);`) : ''}

    }`}
`;

function Dropdown({
  isOpen = false,
  arrow = false,
  position = "left",
  styles,
  spacesTop = 0,
  children,
  portal = false,
  spaces = 0,
  getOpen,
  content,
  arrowSpace = 0
}) {
  const [open, setOpen] = useState(isOpen);
  const [style, setStyle] = useState({});
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    window.addEventListener('resize', checkResizeElement);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
      window.removeEventListener('resize', checkResizeElement);
    }
  }, []);

  useEffect(() => {
    if (isOpen !== open)
      setOpen(isOpen);
  }, [isOpen]);

  const handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(dropdownRef.current);
    // TODO реализовать fix для портала
    if (!domNode.contains(event.target)) {
      setOpen(false);
    }
  }

  const checkResizeElement = () => {
    setOpen(false);
  }

  const toggleDropdown = () => {
    dropdownRef.current && setPosition();
    setOpen(!open);
    getOpen && tgetOpen(!open);
  }

  const setPosition = () => {
    const style = {};
    const dimensions = dropdownRef.current.getBoundingClientRect();
    if (portal) {
      style.top = dimensions.top + dimensions.height + spaces;
    } else {
      style.top = dimensions.height + spaces;
    }

    switch (position) {
      case 'left':
        if (portal)
          style.left = dimensions.left;
        else
          style.transform = dropdownGetStyles(0, dimensions);
        break;
      case 'center':
        if (portal)
          style.left = dimensions.left + dimensions.width * 0.5;
        else
          style.left = '50%';
        style.transform = dropdownGetStyles(-50, dimensions);
        break;
      case 'right':
        if (portal)
          style.left = dimensions.left + dimensions.width;
        else
          style.left = dimensions.width;
        style.transform = dropdownGetStyles(-100, dimensions);
        break;
      default:
        style.transform = dropdownGetStyles(0, dimensions);
        break;
    }
    setStyle(style);
  }

  const dropdownGetStyles = (percent, dimensions) => {
    return `translateX(${percent}%)`;
  }

  const DropdownBody = (
    <ContentWrap
      arrow={arrow}
      arrowSpace={arrowSpace}
      position={position}
      style={{ ...style, ...styles }}
      spacesTop={spacesTop}
    >
      {content}
    </ContentWrap>
  );

  return (
    <DropdownWrap ref={dropdownRef}>
      {React.cloneElement(children, {
        onClick: toggleDropdown
      })}
      {portal && <Portal isOpen={open}>{DropdownBody}</Portal>}
      {!portal && open && DropdownBody}
    </DropdownWrap>
  )
}

export default Dropdown;
