import { ICON } from "../../elements/Icon";
import { BUTTON_SIZE } from '../../const/sizes';
import { GridDirection, GridItemsAlignment, GridJustification, GridSpacing } from "@material-ui/core";

export type icon = keyof typeof ICON;
export type value = string | number;

export enum TABS_LINE_POSITIONS {
  left = 'left',
  right = 'right'
}

export interface ITabs<T extends (string | number)> {
  value: T;
  lineSize?: number;
  linePosition?: keyof typeof TABS_LINE_POSITIONS;
  elastic?: boolean;
  tabSize: keyof typeof BUTTON_SIZE;
  onChange: (e: React.MouseEvent<HTMLElement, MouseEvent>, newValue: T) => void;
  spacing: GridSpacing;
  direction: GridDirection;
  justify: GridJustification
  alignItems: GridItemsAlignment,
}

export interface ITab<T extends (string | number)> {
  label?: string;
  icon?: icon;
  value: T
}