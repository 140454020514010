import styled from "styled-components";
import { SliderBarColor, SliderBgColor } from "./types";


export const StyledSlider = styled.div`
  position: relative;
  display: flex;
  height: 32px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;


export const Line = styled.div`
  padding: 4px 0;
  flex: 1 1 0;

  &:hover {
    cursor: pointer;
  }
`;


export const VisualLine = styled.div`
  position: relative;
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 4px;
  border-radius: 4px;
  background: ${({ theme, bgColor }) => bgColor === SliderBgColor.main ?
    theme.button.default : 'transparent'
  };

  &:before {
    content: '';
    position: absolute;
    width: ${({ value }) => value}%;
    border-radius: 4px;
    left: 0;
    top: 0;
    bottom: 0;
    background: ${({ theme, barColor }) => barColor === SliderBarColor.primary ?
    theme.button.primary : theme.background.hover
  }}
`;