import styled from 'styled-components';
import { IAnchor, IContentStyled } from './types';
import { calcAnchorPosition } from './utils';

export const Wrapper = styled.div`
  position: relative;
  width: max-content;
`;

export const ContentWrapper = styled.div<IContentStyled>`
  position: absolute;
  z-index: 999999;

  ${props => `
    min-width: ${props.minWidth}px;
    max-width: ${props.maxWidth}px;

    // TODO: Add arrow styles
    // ${props.arrow?.isDisplayed && `
    //   &::before {
    //     content: " ";
    //     position: absolute;
    //     border-width: ${props.arrow.size}px;
    //     border-style: solid;
    //     border-color: transparent transparent #fff transparent;
    //     transform: translate(-50%, -100%);
    //     z-index: 101;
    //   }
    // `}
  `}
`;

export const Anchor = styled.span<IAnchor>`
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.button.primary};

  ${props => !props.isDisplayed && `
    visibility: hidden;
  `}

  ${(props) => calcAnchorPosition(props)}
`;