import { FONTS, FONT_FAMILY } from '../const/';

const MAIN = {
  name: 'Main',

  spacing: 8,
  font: FONTS,
  fontFamily: FONT_FAMILY.default,

  background: {
    default: 'hsla(0,0%,100%,1)',
    primary: 'hsla(0,0%,98%,1)',
    secondary: 'hsla(0,0%,96%,1)',

    contrast: 'hsla(200,64%,10%,1)',

    brand: 'hsla(352,88%,56%,1)',

    hover: 'hsla(352,100%,96%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',
  },
  menu: {
    default: 'hsla(0,0%,100%,1)',
    collapsed: 'hsla(352,88%,56%,1)',
  },
  border: {
    default: 'hsla(0,0%,0%,.08)',
    contrast: 'hsla(0,0%,100%,.08)',
  },
  text: {
    default: 'hsla(232,8%,8%,1)',
    primary: 'hsla(352,88%,56%,1)',
    secondary: 'hsla(232,8%,56%,1)',
    disabled: 'hsla(232,8%,64%,1)',

    contrast: 'hsla(232,8%,98%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,40%,56%,1)',
    warning: 'hsla(48,96%,48%,1)',

    dark: 'hsla(232,8%,24%,1)',
    light: 'hsla(232,8%,98%,1)',
  },
  button: {
    default: 'hsla(0,0%,88%,1)',
    primary: 'hsla(352,88%,56%,1)',
    secondary: 'hsla(352,88%,56%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',
  },
  icon: {
    color: {
      default: 'hsla(232,8%,24%,1)',
      primary: 'hsla(352,88%,56%,1)',
      secondary: 'hsla(232,8%,56%,1)',
      disabled: 'hsla(232,8%,64%,1)',

      contrast: 'hsla(232,8%,98%,1)',

      error:'hsla(352,88%,56%,1)',
      success: 'hsla(120,40%,56%,1)',
      warning: 'hsla(48,96%,48%,1)',

      dark: 'hsla(232,8%,24%,1)',
      light: 'hsla(232,8%,98%,1)',
    },
  },
  link: {
    default: 'hsla(352,88%,56%,1)',
    hover: 'hsla(352,88%,48%,1)',
    active: 'hsla(352,88%,40%,1)',
    disabled: 'hsla(232,8%,64%,1)',
  },
};

const MAIN_DARK = {
  name: 'Main Dark',

  spacing: 8,
  font: FONTS,
  fontFamily: FONT_FAMILY.default,

  background: {
    default: 'hsla(200,80%,8%,1)',
    primary: 'hsla(200,48%,12%,1)',
    secondary: 'hsla(200,88%,6%,1)',

    contrast: 'hsla(0,0%,100%,1)',

    brand: 'hsla(352,88%,56%,1)',

    hover: 'hsla(272,16%,16%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',
  },
  menu: {
    default: 'hsla(200,64%,10%,1)',
    collapsed: 'hsla(352,88%,56%,1)',
  },
  border: {
    default: 'hsla(0,0%,100%,.08)',
    contrast: 'hsla(0,0%,0%,.08)',
  },
  text: {
    default: 'hsla(232,8%,98%,1)',
    primary: 'hsla(352,88%,56%,1)',
    secondary: 'hsla(232,8%,56%,1)',
    disabled: 'hsla(232,8%,40%,1)',

    contrast: 'hsla(232,8%,24%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',

    dark: 'hsla(232,8%,24%,1)',
    light: 'hsla(232,8%,98%,1)',
  },
  button: {
    default: 'hsla(200,32%,28%,1)',
    primary: 'hsla(352,88%,56%,1)',
    secondary: 'hsla(352,88%,56%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',
  },
  icon: {
    color: {
      default: 'hsla(232,8%,98%,1)',
      primary: 'hsla(352,88%,56%,1)',
      secondary: 'hsla(232,8%,56%,1)',
      disabled: 'hsla(232,8%,40%,1)',

      contrast: 'hsla(232,8%,24%,1)',

      error: 'hsla(352,88%,56%,1)',
      success: 'hsla(120,64%,72%,1)',
      warning: 'hsla(48,96%,64%,1)',

      dark: 'hsla(232,8%,24%,1)',
      light: 'hsla(232,8%,98%,1)',
    },
  },
  link: {
    default: 'hsla(352,88%,56%,1)',
    hover: 'hsla(352,88%,48%,1)',
    active: 'hsla(352,88%,40%,1)',
    disabled: 'hsla(232,8%,40%,1)',
  },
};

export { MAIN, MAIN_DARK };
