import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MenuItem } from "./";
import { useMenuState } from './menuContext';

const StyledMenuFooter = styled.footer`
  flex-shrink: 0;
  flex-grow: 0;
  border-top: 1px solid ${({ light }) => light ? 'hsla(0,0%,0%,0.08)' : 'hsla(0,0%,100%,0.08)'};
`;

function MenuFooter(props) {
  const {
    onClick,
    footerText,
    ...other
  } = props;

  const { light, small } = useMenuState();

  return (
    <StyledMenuFooter light={light} {...other}>
      <MenuItem text={footerText} icon={small ? "openMenu" : "closeMenu"} onClick={onClick} />
    </StyledMenuFooter>
  );
}

MenuFooter.propTypes = {
  onClick: PropTypes.func,
  footerText: PropTypes.string,
};

MenuFooter.defaultProps = {
  onClick: undefined,
  text: "",
};

export default MenuFooter;
