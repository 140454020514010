import React, { Children, useState, useEffect } from "react";
import styled from "styled-components";

import { FormContextProvider } from './FormContextProvider';
import FormItem from './FormItem';

const FormView = styled.form``;

function Form({ children, type = "blur", onSubmit }) {
  const [dataValues, setValues] = useState({});
  const [dataErrors, setErrors] = useState({});
  const [lastChangedField, setLastChangedFiled] = useState('');
  const [submited, setSubmited] = useState();
  const [allowSubmit, setAllowSubmit] = useState();

  const hanlderSetConfig = ({ name, value }) => {
    setValues((prevState) => ({ ...prevState, [name]: value, }));
    setLastChangedFiled(name);
  }

  const handlerSetError = ({ name, isValid, message, showError }) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: {
        isValid,
        message,
        showError: showError && !isValid,
      },
    }));
  }

  const handlerSubmit = (e) => {e.preventDefault(); setSubmited(Date.now());};

  useEffect(() => {
    if (submited)
      setAllowSubmit(v => !v);
  }, [submited]);

  useEffect(() => {
    if (submited) {
      if (!Object.keys(dataErrors).some(el => !dataErrors[el].isValid)) {
        onSubmit && onSubmit(dataValues);
      }
    }
  }, [allowSubmit]);

  return (
    <FormContextProvider value={{
      callbackSetErrors: handlerSetError,
      callbackSetValues: hanlderSetConfig,
      errors: dataErrors,
      values: dataValues,
      type,
      lastChangedField,
      submited,
    }}>
      <FormView onSubmit={handlerSubmit}>
        {Children.map(children, child => {
          return (
            <FormItem>
              {child}
            </FormItem>
          )
        })}
      </FormView>
    </ FormContextProvider>
  );
}

export default Form;
