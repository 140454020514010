import React, { FC, ReactNode } from 'react';
import { Theme } from 'storybook-addon-styled-component-theme/dist/types/Theme';
import styled from 'styled-components';
import { MAIN } from '../../themes';


export interface PanelProps {
  width?: string;
  height?: string;
  children?: ReactNode;
  color?: keyof typeof MAIN.background;
  // TODO: add props padding
  borderRadius?: number;
  opacityValue?: number;
}


const Wrapper = styled.div<PanelProps & Theme>`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  box-sizing: border-box;
  border-radius: ${({ borderRadius, theme }) => borderRadius && borderRadius * theme.spacing}px;
  box-shadow: 0px 2px 1px -1px hsla(0, 0%, 0%, 0.2),
  0px 1px 1px 0px hsla(0, 0%, 0%, 0.14),
  0px 1px 3px 0px hsla(0, 0%, 0%, 0.12);

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: ${({ color, theme }) => color && theme.background[color]};
    border-radius: ${({ borderRadius, theme }) => borderRadius && borderRadius * theme.spacing}px;
    opacity: ${({ opacityValue }) => opacityValue};
    z-index: -1;
  }
`;


const Panel: FC<PanelProps> = ({
  width = '100%', height = '100%', color = 'default',
  children, borderRadius = 0, opacityValue = 1 }) => {


  return (
    <Wrapper
      width={width}
      height={height}
      color={color}
      borderRadius={borderRadius}
      opacityValue={opacityValue}
    >
      {/* TODO: Add Box component */}
      {children}
    </Wrapper>
  )
}

export default Panel;
