import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { COLORS } from '../../const/';

const StyledSectionBox = styled.div`
  display: block;
  overflow: hidden;
  background: ${({ theme }) => theme.background.default};
  border-radius: 4px;
  margin-bottom: 16px;
  box-shadow: hsla(0,0%,0%,0.04) 0px 0px 1px 1px;

  &:hover {
    box-shadow: hsla(0,0%,0%,.06) 0px 4px 16px 1px;
  }
`;

function SectionBox(props) {
  const {
    children,
    ...other
  } = props;
  return (
    <StyledSectionBox {...other}>
      {children}
    </StyledSectionBox>
  );
}

SectionBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
}

SectionBox.defaultProps = {
  children: undefined,
}

export default SectionBox;
