import React, { ChangeEvent, HTMLAttributes, ReactNode } from "react"


export enum SliderBgColor {
  main, none
}


export enum SliderBarColor {
  primary, secondary
}


export type SliderArgs = HTMLAttributes<HTMLInputElement> & {
  value: number;
  min: number;
  max: number;
  bgColor: SliderBgColor;
  onChangeBegin?: (e: React.PointerEvent) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeEnd?: (e: React.PointerEvent) => void;
  // others: HTMLAllCollection
}


export type TrackbarArgs = {
  value: number;
  bgColor: SliderBgColor;
  barColor: SliderBarColor;
  children: ReactNode
}