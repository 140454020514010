import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { GRID_JUSTIFY_TYPES } from '../GridLayout/types';

type Props = Partial<{ // !!TEMPORARY, replace with proper types later!!
  children: ReactNode;
  align: AlignSetting;
  justify: keyof typeof GRID_JUSTIFY_TYPES | string;
  padding: number;
  height: string;
  width: string;
  overflowY: 'visible' | 'hidden' | 'scroll' | 'auto';
  direction: 'row' | 'column' | string;
}>

const Content = styled.div<Props>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => props.direction};
  padding: ${(props) => props.padding}px;
  ${(props) =>
    props.overflowY &&
    `
    overflow-y: ${props.overflowY};
  `}
`;

function Container({
  children,
  align = 'center',
  justify = 'center',
  padding = 16,
  height = '100%',
  width = '100%',
  overflowY = undefined,
  direction = 'row',
  ...rest
}: Props) {
  return (
    <Content
      overflowY={overflowY}
      height={height}
      width={width}
      align={align}
      justify={justify}
      padding={padding}
      direction={direction}
      {...rest}
    >
      {children}
    </Content>
  );
}

export default Container;
