import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "../Icon/";

const StyledBulletedList = styled.ul`
  padding: 0;
  margin: -6px 0;
  list-style: none;
`;

const StyledBulletedListItem = styled.li`
  display: flex;
  flex-flow: row nowrap;
  align-items: ${props => props.bulletAlign === 'top' ? 'flex-start' : props.bulletAlign === 'bottom' ? 'flex-end' : props.bulletAlign};
  padding: ${props => props.indent * props.theme.spacing}px 0;

  ${props => props.border && (`
    border-bottom: 1px solid ${props.theme.border.default};
  `)}

  &:last-child {
    border: none;
  }
`;

const Bullet = styled.div`
  flex: 0 0 20px;
  margin-right: 12px;
`;

const Text = styled.div`
  font: ${props => props.theme.font.s};
  color: ${props => props.textColor ? props.theme.text[props.textColor] ? props.theme.text[props.textColor] : props.textColor : props.theme.text.default};
`;

function BulletedList(props) {
  const {
    list,
    bullet,
    bulletColor,
    border,
    indent,
    children,
    textColor,
    bulletAlign,
    ...other
  } = props;
  return (
    <StyledBulletedList {...other}>
      {list && list.map((item, i) =>
        <StyledBulletedListItem border={border} key={item} indent={indent} bulletAlign={bulletAlign}>
          {bullet && (
            <Bullet>
              {typeof bullet === "string" || typeof bullet === "boolean" ? (
                <Icon icon={typeof bullet === "boolean" ? "bullet" : bullet} color={bulletColor} size="s" />
              ) : (
                React.cloneElement(bullet, { number: i })
              )}
            </Bullet>
          )}
          <Text textColor={textColor}>{item}</Text>
        </StyledBulletedListItem>
      )}
      {children && (
        React.Children.map(children, (child, i) => (
         <StyledBulletedListItem border={border} key={children} indent={indent} bulletAlign={bulletAlign}>
           {bullet && (
             <Bullet>
               {typeof bullet === "string" || typeof bullet === "boolean" ? (
                 <Icon icon={typeof bullet === "boolean" ? "bullet" : bullet} color={bulletColor} size="s" />
               ) : (
                 React.cloneElement(bullet, { number: ++i })
               )}
             </Bullet>
           )}
           {React.cloneElement(child)}
         </StyledBulletedListItem>
        ))
      )}
    </StyledBulletedList>
  )
}

BulletedList.propTypes = {
  list: PropTypes.array,
  indent: PropTypes.number,
  bullet: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  bulletColor: PropTypes.string,
  border: PropTypes.bool,
  textColor: PropTypes.string,
};

BulletedList.defaultProps = {
  list: '',
  bullet: '',
  bulletAlign: 'top',
  indent: 1,
  bulletColor: 'default',
  border: false,
  textColor: 'default',
};

export default BulletedList;
