import { css } from "styled-components";
import { MAIN } from "../..";
import { GRID_MEDIA_SIZES, IContainer, IGrid, IGridView } from "./types";

const BREAKPOINTS = {
  xs: 320,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const columnsCount = 12;

// Генерируем css для контейнера сетки
export const generateContainerCss = ({ wrap = true, direction = 'row', alignItems = 'flex-start', justify = 'flex-start', spacing = 0 }: IContainer) => {
  const styles = {
    display: 'flex',
    width: '100%',
    flexWrap: wrap ? 'wrap' : 'nowrap',
    flexDirection: direction,
    alignItems,
    justifyContent: justify,
    margin: 'unset'
  }

  if (spacing) {
    styles.width = `calc(100% + ${MAIN.spacing * spacing}px)`;
    styles.margin = `-${MAIN.spacing * spacing * 0.5}px`;
  }

  // @ts-ignore
  return css(styles).join(' ').trim();
}

// Генерируем css для элементов сетки
export const generateItemCss = ({ itemSpacing, container }: IGridView) => {
  const styles = {
    margin: '0px',
    padding: '0px'
  }

  if (itemSpacing) {
    styles.padding = `${MAIN.spacing * itemSpacing * 0.5}px`;

    if (container)
      styles.margin = 'none';
  }

  return css(styles).join(' ').trim();
}

// Мапит медиа запросы по брейкпоинтам, если переданы брейкпоинты xs, sm, md, lg, xl
export const generateMediaGrid = (props: IGrid) => {
  const breakpoints = Object.entries(BREAKPOINTS);

  return breakpoints.map(([key, value]) => {
    // @ts-ignore
    if (!props[key]) return '';

    const mediaMin = `(min-width: ${value}px)`;

    const generatedMediaItem = generateBreakpointGrid({
      // @ts-ignore
      breakpointSize: props[key],
      spacing: props.spacing || 0,
      isContainer: props.container,
      isItem: props.item
    });

    return `
      @media only screen and ${mediaMin} {
        ${generatedMediaItem}
      }  
    `
  }).filter(el => el.length).join(' ').trim();
}

// Генерирует размеры элементов сетки по переданным брейкпоинтам от 0-12
export const generateBreakpointGrid = ({ breakpointSize, spacing, isContainer, isItem }: {
  breakpointSize: typeof GRID_MEDIA_SIZES[number];
  spacing: number;
  isContainer?: boolean;
  isItem?: boolean;
}) => {
  let styles = {};

  if (!breakpointSize) return css({}).join(' ');

  if (breakpointSize === 'auto') {
    styles = {
      flex: '0 0 auto',
      maxWidth: 'none'
    };
  } else if (breakpointSize === true) {
    styles = {
      flex: '1 0 0',
      maxWidth: '100%'
    };
  } else {
    const width = `${Math.round((breakpointSize / columnsCount) * 10e7) / 10e5}%`;

    if (isContainer && isItem && spacing !== 0) {
      const fullWidth = `calc(${width} + ${MAIN.spacing * spacing}px)`;
      styles = {
        flex: `0 0 ${fullWidth}`,
        maxWidth: `${fullWidth}`
      };
    } else {
      styles = {
        flex: `0 0 ${width}`,
        maxWidth: `${width}`
      };
    }
  }

  return css(styles).join(' ').trim();
}