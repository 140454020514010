import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled, { ThemeContext } from 'styled-components';
import { MenuHeader, MenuBody, MenuFooter, MenuItem, MenuSection } from "./";
import { MENU_COLORS } from "../../const/";
import { isLight } from "../../assistants/";
import { MenuProvider } from './menuContext';

const StyledMenu = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: ${({ small }) => small ? "56px" : "224px"};
  z-index: 999999;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background: ${({ menuColor }) => menuColor};
`;

const Logo = styled.div``;

function Menu(props) {
  const {
    children,
    header,
    small,
    footerText,
    onCollaps,
    ...other
  } = props;

  const themeContext = useContext(ThemeContext);

  return (
    <MenuProvider light={isLight(small ? themeContext.menu.collapsed : themeContext.menu.default)} small={small} >
      <StyledMenu menuColor={small ? themeContext.menu.collapsed : themeContext.menu.default} small={small} {...other}>
        {header && (
          <MenuHeader>
            {header}
          </MenuHeader>
        )}
        <MenuBody>
          {children}
        </MenuBody>
        {(footerText || onCollaps) && <MenuFooter footerText={footerText} onClick={onCollaps} />}
      </StyledMenu>
    </MenuProvider>
  );
}

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  header: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  small: PropTypes.bool,
  footerText: PropTypes.string,
  onCollaps: PropTypes.func,
};

Menu.defaultProps = {
  children: undefined,
  header: undefined,
  small: false,
  footerText: "",
  onCollaps: '',
};

export default Menu;
