export const BUTTON_SIZE = {
  xxs: 16,
  xs:  24,
  s:   32,
  m:   40,
  l:   48,
  xl:  56,
  xxl: 64,
}

export const SPACING_SIZE = {
  xxs: 0.25,
  xs:  0.25,
  s:   0.5,
  m:   0.5,
  l:   0.75,
  xl:  0.75,
  xxl: 1,
}

export const ICON_SIZE = {
  xxs: 12,
  xs:  16,
  s:   20,
  m:   24,
  l:   28,
  xl:  32,
  xxl: 36,
  modal: 48,
}

export const TEXT_SIZE = {
  xxs: 11,
  xs:  13,
  s:   15,
  m:   17,
  l:   20,
  xl:  24,
  xxl: 32,
}

export const LINE_SIZE = {
  xxs: 12,
  xs:  15,
  s:   20,
  m:   24,
  l:   28,
  xl:  32,
  xxl: 40,
}

export const OBJECT_TEXT_SIZE = {
  xxs: TEXT_SIZE.xxs,
  xs:  TEXT_SIZE.xs,
  s:   TEXT_SIZE.s,
  m:   TEXT_SIZE.s,
  l:   TEXT_SIZE.m,
  xl:  TEXT_SIZE.m,
  xxl: TEXT_SIZE.l,
}

export const OBJECT_LINE_SIZE = {
  xxs: LINE_SIZE.xxs,
  xs:  LINE_SIZE.xs,
  s:   LINE_SIZE.s,
  m:   LINE_SIZE.s,
  l:   LINE_SIZE.m,
  xl:  LINE_SIZE.m,
  xxl: LINE_SIZE.l,
}

export const OBJECT_ICON_SIZE = {
  xxs: 'xxs',
  xs:  'xs',
  s:   's',
  m:   's',
  l:   'm',
  xl:  'm',
  xxl: 'l',
}

export const INDENT_SIZE = {
  xxs: 1,
  xs:  2,
  s:   3,
  m:   4,
  l:   5,
  xl:  6,
  xxl: 7,
}

export const GRID_SIZE = {
  l:    "max-width: 1440px",
  m:    "max-width: 1200px",
  s:    "max-width: 1024px",
  xs:   "max-width: 720px",
  xxs:  "max-width: 576px",
}
