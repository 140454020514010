/* Text colors
---------------------------------------------- */

export const TEXT_COLORS = {
  default: "hsla(0,0%,12%,1)", // Цвет текста по умолчанию
  gray: "hsla(0,0%,48%,1)", // Цвет серого текста
  disabled: "hsla(0,0%,64%,1)",  // Цвет текста когда элемент задисейблин

  defaultLight: "hsla(0,0%,100%,1)",
  grayLight: "hsla(0,0%,64%,1)",
  disabledLight: "hsla(0,0%,72%,1)",

  primary: "hsla(216,96%,56%,1)", // Не использовать
}

export const TEXT_COLORS_OPACITY = {
  default: "hsla(0,0%,0%,.12)",
  gray: "hsla(0,0%,0%,.48)",
  disabled: "hsla(0,0%,0%,.64)",

  defaultLight: "hsla(0,0%,100%,1)",
  grayLight: "hsla(0,0%,100%,.64)",
  disabledLight: "hsla(0,0%,100%,.48)",
}





/* Link colors
---------------------------------------------- */

export const LINK_COLORS = {
  default: "hsla(216,96%,56%,1)",
  hover: "hsla(360,88%,48%,1)",
  active: "hsla(360,88%,48%,1)",
  disabled: "hsla(0,0%,64%,1)",
}





/* Border colors
---------------------------------------------- */

export const BORDER_COLORS = {
  default: "hsla(0,0%,0%,.08)",
  white: "hsla(0,0%,100%,.08)",
}





/* Background colors
---------------------------------------------- */

export const BACKGROUND_COLORS = {
  default: "hsla(0,0%,96%,1)", // Цвет фона по умолчанию
  light: "hsla(0,0%,98%,1)", // Цвет фона светлее основного
  white: "hsla(0,0%,100%,1)", // Белый
}





/* Other colors
---------------------------------------------- */

export const COLORS = {
  default: "hsla(0,0%,12%,1)",
  white: "hsla(0,0%,100%,1)",
  primary: "hsla(120,40%,48%,1)",
  error: "hsla(360,100%,48%,1)",
  success: "hsla(136, 64%, 56%, 1)",
  gray: "hsla(0,0%,40%,1)",
}

export const BUTTON_COLORS = {
  default: "hsla(0,0%,88%,1)", // Цвет кнопки по умолчанию - outline

  gray: "hsla(64,4%,94%,1)",
  white: "hsla(0,0%,100%,1)",
  primary: "hsla(104,40%,64%,1)",
  secondary: "hsla(0,100%,64%,1)",
  transparent: "transparent",
  error: "hsla(360,96%,56%,1)",
}

export const MENU_COLORS = {
  default: "hsla(0,0%,96%,1)",
}
