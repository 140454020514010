import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledMenuBody = styled.div`
  width: 125%;
  min-width: 125%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
`;

const Content = styled.div`
  width: 80%;
  min-width: 224px;
`;

function MenuBody(props) {
  const {
    children,
    ...other
  } = props;
  return (
    <StyledMenuBody {...other}>
      {children && (
        <Content>
          {children}
        </Content>
      )}
    </StyledMenuBody>
  );
}

MenuBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ])
};

MenuBody.defaultProps = {
  children: undefined
};

export default MenuBody;
