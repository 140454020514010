import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../const';
import { IconSize } from '../../const/theme-size-types';
import { FACEMACHINE } from '../../themes';
import Icon from '../Icon';
import { Icons } from '../Icon/types';


type Props = HTMLAttributes<Element> & {
  icon: Icons;
  size: number;
  color?: string;
  iconSize?: IconSize;
}


const IconWrap = styled.div<Pick<Props, "size" | "color">>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;


/* type icons = keyof typeof ICON
const icons: { [i in icons]: i } = {
  i: icons[i]  
} */


function RoundIcon({
  icon, size, color = FACEMACHINE.background.brand,
  iconSize = 'm', ...rest }: Props) {


  return (
    <IconWrap
      size={size}
      color={color}
      {...rest}
    >
      <Icon
        color={COLORS.white}
        size={iconSize}
        icon={icon}
      />
    </IconWrap>
  )
}


export default RoundIcon;
