import React, { FC } from 'react';
import styled from 'styled-components';
import { DIVIDER_ORIENTATIONS, IDivider } from './types';

const DividerView = styled.div<IDivider>`
  position: relative;
  border: none;
  margin: 0;
  flex-shrink: 0;
  background-color: ${props => props.color && props.theme.border[props.color]};
  ${props => props.zIndex && `z-index: ${props.zIndex};`}

  ${props => props.direction === "X" && `
    width: inherit;
    height: ${props.size}px;
  `}
  ${props => props.direction === "Y" && `
    width: ${props.size}px;
    height: inherit;
  `}
`
const Divider: FC<IDivider> = ({ direction = DIVIDER_ORIENTATIONS.X, size = 1, zIndex, color = "default", className }) => (
  <DividerView direction={direction} size={size} color={color} zIndex={zIndex} className={className} />
)

export default Divider;
