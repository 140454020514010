import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled, { ThemeContext } from "styled-components";
import { isLight, colorToHsla } from '../../assistants/';
import { BUTTON_COLORS } from "../../const/";

const StyledBattery = styled.div`
  display: flex;
  flex-flow: ${props => props.direction === "row" ? "row" : "column"} nowrap;
  width: ${props => props.direction === "row" ? "auto" : "100%"};
  padding: ${props => props.direction === "row" ? "0 0 0 1px" : "1px 0 0 0"};

  ${props => props.variant !== "contained" && `
    & > * {
      margin: ${props.direction === "row" ? "0 0 0 -1px" : "-1px 0 0 0"};
    }
  `}
`;

const Separator = styled.div`
  width: ${props => props.direction === "row" ? "1px" : "100%"};
  ${props => props.direction === "row" && "flex: 0 0 1px"};
  height: ${props => props.direction === "row" ? "auto" : "1px"};
  background: ${props => props.separatorColor};
`;

const getSeparatorColor = color => {
  const [h, s, l, a] = colorToHsla(color);
  const light = `hsla(${h},${s}%,${+l - 8 <= 0 ? 0 : +l - 8}%,${a})`;
  const dark = `hsla(${h},${s}%,${+l + 12 >= 100 ? 100 : +l + 12}%,${a})`;
  return (
    isLight(color) ? light : dark
  )
}

const renderChildren = (
  children, count, borderRadius, variant,
  color, direction, themeContext) => {

  const separatorColor = getSeparatorColor(themeContext.button[color]);

  return React.Children.map(children, (child, i) => {
    let newChild;
    switch (i) {
      case 0:
        return (
          <>
            {React.cloneElement(child, {
              borderRadius: direction === "row" ?
                `${borderRadius} 0 0 ${borderRadius}` :
                `${borderRadius} ${borderRadius} 0 0`,
              elastic: direction === "row" ?
                false : true,
              variant: child.props.variant ?
                child.props.variant :
                variant,
              color: child.props.color ?
                child.props.color :
                color
            })}
            {variant === 'contained' &&
              <Separator
                direction={direction}
                separatorColor={separatorColor}
              />}
          </>
        )
        break;
      case count - 1:
        return (
          <>
            {React.cloneElement(child, {
              borderRadius:
                direction === "row"
                  ? `0 ${borderRadius} ${borderRadius} 0`
                  : `0 0 ${borderRadius} ${borderRadius}`,
              elastic: direction === "row" ?
                false : true,
              variant: child.props.variant ?
                child.props.variant :
                variant,
              color: child.props.color ?
                child.props.color :
                color
            })}
          </>
        )
        break;
      default:
        return (
          <>
            {React.cloneElement(child, {
              round: false,
              borderRadius: "0",
              elastic: direction === "row" ?
                false : true,
              variant: child.props.variant ?
                child.props.variant :
                variant,
              color: child.props.color ?
                child.props.color :
                color
            })}
            {variant === 'contained' &&
              <Separator
                direction={direction}
                separatorColor={separatorColor}
              />}
          </>
        )
        break;
    }
  });
};

function Battery(props) {
  const {
    children,
    borderRadius,
    variant,
    color,
    direction,
    ...other
  } = props;
  const count = React.Children.count(children);
  const themeContext = useContext(ThemeContext);
  return (
    <StyledBattery direction={direction} variant={variant} {...other}>
      {count !== 1
        ? renderChildren(children, count, borderRadius, variant, color, direction, themeContext)
        : React.cloneElement(children[0] ? children[0] : children, { borderRadius: borderRadius, round: false, elastic: direction === "row" ? false : true, variant: variant, color: color })}
    </StyledBattery>
  );
}

Battery.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  borderRadius: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string,
}

Battery.defaultProps = {
  children: undefined,
  borderRadius: "4px",
  variant: "outline",
  color: "default",
  direction: "row",
};

export default Battery;
