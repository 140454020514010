import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { COLORS, TEXT_COLORS, LINE_SIZE, TEXT_SIZE, FONTS } from "../../const/";

const StyledModalBody = styled.div`
  padding: 4px 0;
`;

const TitleContainer = styled.div`
  overflow: hidden;
  padding: 8px 0;
  text-align: center;
`;

const Subtitle = styled.div`
  font: ${props => props.theme.font.m};
  color: ${props => props.theme.text.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorText = styled.div`
  margin-top: 16px;
  font: ${props => props.theme.font.xs};
  color: ${props => props.theme.text.light};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 16px;
  border-radius: 4px;
  background: ${props => props.theme.background.error};
  text-align: left;
`;

const Title = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  font: ${FONTS.xl};
  color: ${props => props => props.theme.text.default};
  margin: 0;
  font-weight: 600;

  & ~ ${ Subtitle } {
    padding-top: 8px;
  }
`;

function ModalBody(props) {
  const {
    children,
    title,
    subtitle,
    error,
    textColor,
    ...other
  } = props;
  return (
    <StyledModalBody {...other}>
      {(title || subtitle || error) && (
        <TitleContainer>
          {title && (
            <Title>{title}</Title>
          )}
          {subtitle && (
            <Subtitle>{subtitle}</Subtitle>
          )}
          {error && (
            <ErrorText>{error}</ErrorText>
          )}
        </TitleContainer>
      )}
      {children}
    </StyledModalBody>
  )
}

ModalBody.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
}

ModalBody.defaultProps = {
  title: "",
  subtitle: "",
  error: "",
  children: undefined,
};

export default ModalBody;
